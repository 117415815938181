import React, { useRef, useContext, useState, useEffect, useCallback } from "react";
import { Alert, Input, Button, Modal } from "antd";
import { MisDocumentosContext } from "../../../../../context/v3/MisDocumentosContext";
import { SecurityContext } from "../../../../../context/SecurityContextProvider";
import { Plataforma } from "enum/Plataforma";
import { CategoriaDocumento } from "enum/CategoriaDocumento";
import { visibleVisorFormularioAtom } from "../MisDocumentosWeb/MisDocumentosWebStorage";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";
import SignaturePad from "react-signature-pad-wrapper";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { useMediaQuery } from "react-responsive";

export function ModalFirmar({ plataforma = Plataforma.WEB }) {
	let history = useHistory();
	const [, setVisibleVisorFormulario] = useAtom(visibleVisorFormularioAtom);
	const [claveIngresada, setClaveIngresada] = useState("");
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const {
		loading,
		mensajeError,
		setMensajeError,
		visibleModalFirmar,
		firmarFormulario,
		firmarDocumento,
		documentoSeleccionado,
		datosFormulario,
		cerrarModalFirmar,
		setReloadPdf,
		cerrarVisorPdf,
	} = useContext(MisDocumentosContext);

	const [refSignature, setRefSignature] = useState(undefined);

	const isAceros = getUsuarioAutenticado().empresaSeleccionada.razonSocial.toUpperCase().includes("ACEROS CHILCA SAC")
		? true
		: false;

	const [tieneFirma, setTieneFirma] = useState(false);

	const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });

	const signatureCallBackRef = useCallback((signature) => {
		setRefSignature(signature);
	}, []);

	useEffect(() => {
		const cargarFirma = async () => {
			if (refSignature && documentoSeleccionado?.requiereFirmaHolografaColaborador) {
				const info = await PortalColaboradorService.obtenerUrlFirmaHolografa();
				//refSignature.clear();
				// refSignature.scaleCanvas();
				//refSignature.fromDataURL(info.urlFirmaHolografa);

				if (info.urlFirmaHolografa) {
					setTieneFirma(true);
				}

				// if (isAceros && info.urlFirmaHolografa) {
				// 	setTieneFirma(true);
				// } else {
				// 	refSignature.fromDataURL(info.urlFirmaHolografa);
				// }
			}
		};
		cargarFirma();
	}, [refSignature]);

	function onClickBtnCancelar() {
		setMensajeError(undefined);
		setClaveIngresada("");
		cerrarModalFirmar();
		if (documentoSeleccionado.mostrarVentanaFirmaAntesDeVisualizarDocumento) {
			cerrarVisorPdf();
		}
	}

	const onClickBtnFirmar = async () => {
		if (
			documentoSeleccionado.categoriaDocumentoId === CategoriaDocumento.FORMULARIO ||
			documentoSeleccionado.categoriaDocumentoId === CategoriaDocumento.FORMATO_AUSENCIA
		) {
			const formulario = {
				detalleEnvioId: documentoSeleccionado.id,
				datos: datosFormulario,
				clave: claveIngresada,
				plataforma,
			};

			const firmaExitosa = await firmarFormulario(formulario);

			if (firmaExitosa) {
				setMensajeError(undefined);
				setClaveIngresada("");
				setVisibleVisorFormulario(false);
				if (plataforma.includes("MOVIL_")) {
					history.push(`/v3/mis-pendientes/visor-pdf/${documentoSeleccionado.id}/true`);
				}
			}
		} else {
			if (documentoSeleccionado?.requiereFirmaHolografaColaborador && !tieneFirma && refSignature.isEmpty()) {
				setMensajeError("Dibuje su firma hológrafa");
				return;
			}

			if (claveIngresada === "") {
				setMensajeError("Ingresa tu clave");
				return;
			}

			const base64ImagenFirmaColaborador =
				documentoSeleccionado.requiereFirmaHolografaColaborador && !tieneFirma
					? refSignature.toDataURL()
					: null;

			const firmaExitosa = await firmarDocumento(
				getUsuarioAutenticado().empresaSeleccionada.id,
				documentoSeleccionado.id,
				claveIngresada,
				plataforma,
				base64ImagenFirmaColaborador
			);
			if (firmaExitosa === true) {
				setMensajeError(undefined);
				setClaveIngresada("");
				setReloadPdf(true);
			}
		}
	};

	const onClickBtnLimpiarFirma = () => {
		refSignature.clear();
	};

	return (
		<Modal
			title="Firmar Documento"
			visible={visibleModalFirmar}
			closable={false}
			width={400}
			destroyOnClose={true}
			footer={[
				<Button key="cancelar" disabled={loading} onClick={onClickBtnCancelar} style={{ padding: "0px 30px" }}>
					Cancelar
				</Button>,
				<Button
					key="firmar"
					type="primary"
					loading={loading}
					onClick={onClickBtnFirmar}
					style={{ padding: "0px 30px" }}
				>
					Firmar
				</Button>,
			]}
			centered
		>
			{mensajeError && (
				<Alert
					message={mensajeError}
					onClose={() => setMensajeError(undefined)}
					style={{ marginBottom: "4px" }}
					type="error"
					showIcon
					closable
				/>
			)}

			<span style={{ display: "block", marginBottom: "4px" }}>Ingresa tu clave</span>
			<Input.Password
				value={claveIngresada}
				onChange={(e) => {
					setClaveIngresada(e.target.value);
					setMensajeError(undefined);
				}}
				placeholder={"Clave"}
			/>

			{documentoSeleccionado?.requiereFirmaHolografaColaborador && !tieneFirma && (
				<div style={{ paddingTop: "10px" }}>
					<div>Dibuja tu firma</div>
					<SignaturePad
						ref={signatureCallBackRef}
						// width={esDesktop ? 340 : 170}
						// height={esDesktop ? 150 : 80}
						canvasProps={{
							style: { border: "1px dashed #ccc" },
						}}
						options={{
							minWidth: 2,
							maxWidth: 3,
							penColor: "#000",
						}}
						redrawOnResize={true}
					/>
					<div style={{ textAlign: "right" }}>
						<Button type="link" onClick={onClickBtnLimpiarFirma} style={{ padding: "0" }}>
							Limpiar Firma
						</Button>
					</div>
				</div>
			)}
		</Modal>
	);
}
