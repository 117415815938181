import React from "react";
import styled from "@emotion/styled";
import { Tag, Checkbox } from "antd";
import { EditOutlined, CalendarOutlined } from "@ant-design/icons";

const ItemTareaPendiente = ({ id, tipo, descripcion, fecha, checked, onSeleccionar }) => {
	return (
		<Container onClick={() => onSeleccionar(id)}>
			<Contenido>
				<Seleccion>
					<Checkbox checked={checked} onChange={() => onSeleccionar(id)} />
				</Seleccion>
				<Texto>{descripcion}</Texto>
			</Contenido>
			<Footer>
				<Fecha>
					<CalendarOutlined style={{ fontSize: "14px", marginRight: "2px", color: "#6c757d" }} />
					{fecha}
				</Fecha>
				<Etiqueta>
					<Tag color="gold">
						<EditOutlined />
						&nbsp; {tipo}
					</Tag>
				</Etiqueta>
			</Footer>
		</Container>
	);
};

const Footer = styled.div`
	border-top: 1px solid #ccc;
	height: 30%;
	font-size: 12px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
`;

const Fecha = styled.div`
	display: flex;
	align-items: center;
	text-align: left;
	flex: 1 1 50%;
	padding-left: 10px;
`;

const Etiqueta = styled.div`
	text-align: right;
	flex: 1 1 50%;
	padding-right: 10px;
`;

const Container = styled.div`
	border: 1px solid #ccc;
	height: 120px;
	cursor: pointer;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	margin-bottom: 15px;
`;

const Contenido = styled.div`
	height: 70%;
	font-size: 12px;
	display: flex;
	padding: 10px 0px;
`;

const Seleccion = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	padding: 20px;
	color: #d48806;
`;

const Texto = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
	padding-left: 0px;
	text-align: justify;
`;

export default ItemTareaPendiente;
