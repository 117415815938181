import React from "react";
import "./App.less";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import SecurityContextProvider from "./context/SecurityContextProvider";
import { LoginPage, LoginEmpresaPage, LoginGenericoPage, LoginActiveDirectoryPage, LoginActiveDirectoryValidationPage } from "./pages";
import { RutaCortaPage } from "pages/ruta-corta";
import { ActualizarClavePage } from "./pages/cuenta/actualiza-clave";
import { GenerarClavePage } from "./pages/cuenta/generar-clave";
import { PrivateRoute } from "./components/PrivateRouter";
import { RutasApp } from "./router/RutasApp.js";
import { pdfjs } from "react-pdf";
import locale from "antd/lib/locale/es_ES";
import { ConfigProvider } from "antd";
import { VerificarDatosPersonalesPage } from "pages/verificar-datos-personales";
import { AuthLoginPage } from "pages/auth";

function App() {
	/* Nota: Para que funciona pdfjs con es5 react-pdf debe estar en la version 4.2.0 */
	/* Nota: Se debe invocar al pdf.worker.js local porque está modificado para mostrar la firma digital */
	console.log("pdfjs.version: " + pdfjs.version);
	// pdfjs.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + "/pdf.worker.5.js";
	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

	return (
		<SecurityContextProvider>
			<ConfigProvider locale={locale} input={{ autoComplete: "off" }}>
				<BrowserRouter>
					<Switch>
						<Route exact path="/login" component={LoginPage} />
						<Route exact path="/e/login" component={LoginEmpresaPage} />
						<Route exact path="/g/login" component={LoginGenericoPage} />
						<Route exact path="/ad/login" component={LoginActiveDirectoryPage} />
						<Route exact path="/ad/login/validation" component={LoginActiveDirectoryValidationPage} />

						<Route exact path="/r/:hashUrl" component={RutaCortaPage} />
						<Route exact path="/actualizarClave" component={ActualizarClavePage} />
						<Route exact path="/generarClave" component={GenerarClavePage} />
						<Route exact path="/verificarDatosPersonales" component={VerificarDatosPersonalesPage} />
						<Route exact path="/auth/login" component={AuthLoginPage} />
						{/* <Route exact path="/publico/convocatorias" component={ConvocatoriasPage} />
						<Route exact path="/publico/registro-postulante/:cargo" component={FormularioPostulantePage} /> */}
						<PrivateRoute exact component={RutasApp} />
					</Switch>
				</BrowserRouter>
			</ConfigProvider>
		</SecurityContextProvider>
	);
}

export default App;
