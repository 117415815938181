import React from "react";
import styled from "@emotion/styled";
import { Errores, InicializarValores, SignatureModal, Visor, Zoom, MotivoRechazo } from "./componentes";
import { Loading } from "./componentes/Loading";
import PdfNoEspecificado from "./componentes/PdfNoEspecificado/PdfNoEspecificado";

const VisorFormulario = ({
	type,
	form,
	urlPdf,
	componentes,
	variablesGlobales,
	datosFormulario,
	urlFirmaHolografa,
	urlHuellaDactilar,
	urlFoto,
	setLoadingPdf,
	motivoRechazo,
}) => {
	return (
		<Container>
			<InicializarValores
				type={type}
				form={form}
				urlPdf={urlPdf}
				componentes={componentes}
				variablesGlobales={variablesGlobales}
				datosFormulario={datosFormulario}
				urlFirmaHolografa={urlFirmaHolografa}
				urlHuellaDactilar={urlHuellaDactilar}
				urlFoto={urlFoto}
				setLoadingPdf={setLoadingPdf}
				motivoRechazo={motivoRechazo}
			/>
			<Loading />
			{/* <PdfNoEspecificado /> */}
			{/* <Errores /> */}
			{type === "form" && <MotivoRechazo />}
			{type === "form" && <Errores />}
			<Zoom />
			{urlPdf && <Visor setLoadingPdf={setLoadingPdf} />}
			{/* <SignatureModal /> */}
			{type === "form" && <SignatureModal />}
		</Container>
	);
};
const Container = styled.div`
	#border: 1px solid #ccc;
	height: 100%;
	width: 100%;
	position: relative;
`;

export default VisorFormulario;
