import { useEffect } from "react";
import { useAtom } from "jotai";
import { TareasPendientesService } from "services";
import { tareasPendientesAtom } from "pages/tareas-pendientes/TareasPendientesStorage";

const useListarTareasPendientes = () => {
	const [tareasPendientes, setTareasPendientes] = useAtom(tareasPendientesAtom);

	useEffect(() => {
		const cargarDatos = async () => {
			const respuesta = await TareasPendientesService.listar();
			setTareasPendientes(respuesta);
		};
		cargarDatos();
	}, []);
	return { tareasPendientes };
};

export default useListarTareasPendientes;
