import React, { useEffect } from "react";
import { useListarDocumentos } from "pages/onboarding/componentes/common/hooks";
import { ItemDocumento } from "pages/onboarding/componentes/common/componentes";
import styled from "@emotion/styled";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
	documentoSeleccionadoAtom,
	informacionVisorAtom,
	rutaSubirDocumentoAtom,
	visibleSubirDocumentosAtom,
	visibleVisorAtom,
} from "pages/onboarding/OnboardingStore";
import { CategoriaDocumento } from "enum/CategoriaDocumento";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { EstadoVistaColaborador } from "enum/EstadoVistaColaborador";
import { EstadoAprobacion } from "enum/EstadoAprobacion";
import { Plataforma } from "enum/Plataforma";

const ListaDocumentos = () => {
	const documentoSeleccionado = useAtomValue(documentoSeleccionadoAtom);
	const setInformacionVisor = useSetAtom(informacionVisorAtom);
	const setVisibleVisor = useSetAtom(visibleVisorAtom);
	const setVisibleSubirDocumentos = useSetAtom(visibleSubirDocumentosAtom);
	const { documentos } = useListarDocumentos();

	useEffect(() => {
		const obtenerInformacion = async () => {
			if (documentoSeleccionado) {
				if (
					documentoSeleccionado.estadoVistaColaborador ===
						EstadoVistaColaborador.FIRMADO &&
					documentoSeleccionado?.estadoAprobacion !==
						EstadoAprobacion.RECHAZADO
				) {
					const respuesta = await PortalColaboradorService.obtenerUrl(
						undefined,
						documentoSeleccionado.id,
						Plataforma.WEB
					);
					setInformacionVisor({
						type: "visor",
						urlPlantillaPdfFirmada: respuesta.urlDocumento,
						componentes: [],
						variablesGlobales: {},
						datosFormulario: {},
					});
					setVisibleVisor(true);
					setVisibleSubirDocumentos(false);
				} else {
					if (
						documentoSeleccionado.categoriaDocumentoId ===
						CategoriaDocumento.FORMULARIO
					) {
						const respuesta =
							await PortalColaboradorService.obtenerDatosFormulario(
								documentoSeleccionado.formularioId,
								documentoSeleccionado.id
							);
						setInformacionVisor({
							type: "form",
							urlPlantillaPdfFirmada:
								respuesta.urlPlantillaPdfFirmada,
							componentes: respuesta.componentes,
							variablesGlobales: respuesta.variablesFormulario,
							datosFormulario: respuesta.datosFormulario,
							urlHuellaDactilar: respuesta.urlHuellaDactilar,
							urlFirmaHolografa: respuesta.urlFirmaHolografa,
							urlFoto: respuesta?.urlFoto,
							motivoRechazo: respuesta?.motivoRechazo,
						});
						setVisibleVisor(true);
						setVisibleSubirDocumentos(false);
					} else if (
						documentoSeleccionado.categoriaDocumentoId ===
						CategoriaDocumento.CARGA_DOCUMENTO
					) {
						setVisibleVisor(false);
						setVisibleSubirDocumentos(true);
					}
				}
			}
		};

		obtenerInformacion();
	}, [documentoSeleccionado]);

	return (
		<Container className="scrollbar-container">
			{documentos &&
				documentos.map((documento) => {
					return (
						<ItemDocumento
							key={documento.id}
							documento={documento}
						/>
					);
				})}
		</Container>
	);
};

const Container = styled.div`
	flex: 1 1 30%;
	margin: 10px;
	overflow: auto;
`;

export default ListaDocumentos;
