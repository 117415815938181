import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Provider as JotaiProvider } from "jotai";
import { MisDocumentosPageWeb } from './componentes/web';
import { MisDocumentosPageMovil } from './componentes/movil';


const MisDocumentosPage = () => {
    const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });
    const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
    return (
        <JotaiProvider>
            {esDesktop && <MisDocumentosPageWeb />}
            {esMovil && <MisDocumentosPageMovil />}
        </JotaiProvider>
    );
};

export default MisDocumentosPage;