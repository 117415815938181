import React, { useContext } from "react";
import { SecurityContext } from "../../context/SecurityContextProvider";
import { Route, Redirect, useHistory } from "react-router-dom";
import { PortalColaboradorService } from "services/PortalColaboradorService";

let subdominio = window.location.host.split(".")[0];

export const PrivateRoute = ({ component: Component, ...rest }) => {
	const { estaAutenticado, getUsuarioAutenticado, setActualizarSesionUsuario, setEmpresaSeleccionada } =
		useContext(SecurityContext);
	let isOnboarding = false;

	if (estaAutenticado) {
		if (subdominio === "app") {
			subdominio = "MOVIL";
		}

		PortalColaboradorService.obtenerSesionUsuarioActualizada({ subdominio: subdominio })
			.then((response) => {
				const empresaSeleccionadaId = getUsuarioAutenticado().empresaSeleccionada.id;
				setActualizarSesionUsuario(response.data);
				setEmpresaSeleccionada(empresaSeleccionadaId);
			})
			.catch((error) => {
				console.log("no se pudo actualizar la sesión del usuario", error);
			});

		if (getUsuarioAutenticado()?.empresaSeleccionada.moduloOnboardingHabilitado) {
			if (
				getUsuarioAutenticado().empresaSeleccionada.colaborador.estado === "ONBOARDING" ||
				getUsuarioAutenticado().empresaSeleccionada.colaborador.estado === "ACTIVO"
			) {
				if (
					!getUsuarioAutenticado().empresaSeleccionada.colaborador.informacionPersonalCompletado ||
					(!getUsuarioAutenticado().empresaSeleccionada.colaborador.formulariosDigitalesCompletado &&
						getUsuarioAutenticado().empresaSeleccionada.colaborador.estado === "ONBOARDING")
				) {
					isOnboarding = true;
				}
			}
		}
	}

	return (
		<Route
			{...rest}
			render={(props) =>
				estaAutenticado() === true ? (
					<Component {...props} isOnboarding={isOnboarding} />
				) : (
					<Redirect to="/login" />
				)
			}
		/>
	);
};
