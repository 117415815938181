import { BaseService } from "./BaseService";
const ActualizarClaveService = {
	async cambiarClave(
		subdominio,
		numeroDocumentoIdentidad,
		clave,
		claveActual
	) {
		const respuesta = await BaseService.post(
			"/cuenta/cambiarClaveColaborador",
			{
				subdominio: subdominio,
				numeroDocumentoIdentidad: numeroDocumentoIdentidad,
				clave: clave,
				claveActual: claveActual,
			}
		);
		return respuesta.data;
	},
};
export default ActualizarClaveService;
