import React, { useState } from "react";
import {
	Button,
	Col,
	DatePicker,
	Divider,
	Form,
	Input,
	Row,
	Select,
	Upload,
	message,
	Space,
	Radio,
	Collapse,
	Card,
} from "antd";
import { TipoDocumentoIdentidad } from "enum/TipoDocumentoIdentidad";
import {
	RightOutlined,
	UploadOutlined,
	HomeOutlined,
	IdcardOutlined,
	RiseOutlined,
	PhoneOutlined,
	EditOutlined,
	WalletOutlined,
} from "@ant-design/icons";
import { useCargarInformacionPersonal } from "../../hooks";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { OnboardingService } from "services";
import { useHistory } from "react-router-dom";
import { pasoActualAtom } from "pages/onboarding/OnboardingStore";
import { useEffect } from "react";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import ImgCrop from "antd-img-crop";

const firmaHolografaAtom = atom([]);
const huellaDactilarAtom = atom([]);
const fotoPersonalAtom = atom([]);

const FormularioInformacionPersonal = ({ formulario }) => {
	// const { form } = useCargarInformacionPersonal();
	const { Panel } = Collapse;
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const ismaccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const ismaccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;
	//console.log("XXX::", getUsuarioAutenticado().empresaSeleccionada.colaborador.estado);

	return (
		<Form layout="vertical" form={formulario}>
			{getUsuarioAutenticado().empresaSeleccionada.colaborador.estado == "ONBOARDING" && (
				<Space size={"large"} direction="vertical" style={{ marginBottom: "50px" }}>
					<Card
						type="inner"
						title={
							<span>
								<EditOutlined /> Información personal
							</span>
						}
					>
						<DatosPersonales />
					</Card>
					{!ismaccaferriDePeru && !ismaccaferriConstruction && (
						<Card
							type="inner"
							title={
								<span>
									<RiseOutlined /> Regimen Pensionario
								</span>
							}
						>
							<RegimenPensionario form={formulario} />
						</Card>
					)}
					{!ismaccaferriDePeru && !ismaccaferriConstruction && (
						<Card
							type="inner"
							title={
								<span>
									<WalletOutlined /> Cuenta Haberes
								</span>
							}
						>
							<CuentaBancaria form={formulario} />
						</Card>
					)}
					{!ismaccaferriDePeru && !ismaccaferriConstruction && (
						<Card
							type="inner"
							title={
								<span>
									<WalletOutlined /> Cuenta CTS
								</span>
							}
						>
							<CuentaCTS form={formulario} />
						</Card>
					)}
					<Card
						type="inner"
						title={
							<span>
								<HomeOutlined /> Domicilio Actual
							</span>
						}
					>
						<DomicilioActual />
					</Card>
					<Card
						type="inner"
						title={
							<span>
								<IdcardOutlined /> Lugar de nacimiento
							</span>
						}
					>
						<DatosNacimiento />
					</Card>
					<Card
						type="inner"
						title={
							<span>
								<PhoneOutlined /> Contacto de Emergencias
							</span>
						}
					>
						<ContactoEmergencia />
					</Card>
					<Botones form={formulario} />
				</Space>
			)}
			{getUsuarioAutenticado().empresaSeleccionada.colaborador.estado === "ACTIVO" && (
				<Space size={"large"} direction="vertical" style={{ marginBottom: "50px" }}>
					<Card
						type="inner"
						title={
							<span>
								<EditOutlined /> Información personal
							</span>
						}
					>
						<DatosPersonalesColaboradoraActivo />
					</Card>
					<Botones form={formulario} />
				</Space>
			)}
		</Form>
	);
};

const Botones = ({ form }) => {
	const [loading, setLoading] = useState(false);
	const firmaHolografa = useAtomValue(firmaHolografaAtom);
	const huellaDactilar = useAtomValue(huellaDactilarAtom);
	const fotoPersonal = useAtomValue(fotoPersonalAtom);
	const setPasoActual = useSetAtom(pasoActualAtom);
	const { actualizarEstadoFormulario, getUsuarioAutenticado } = useContext(SecurityContext);
	//const history = useHistory();

	const onClickBtnContinuar = () => {
		form.validateFields()
			.then(async (formulario) => {
				setLoading(true);

				const informacionPersonal = {
					datosPersonales: formulario.datosPersonales,
					domicilioActual: formulario.domicilioActual,
					datosNacimiento: formulario.datosNacimiento,
					regimenPensionario: formulario.regimenPensionario,
					cuentaHaberes: formulario.cuentaHaberes,
					cuentaCTS: formulario.cuentaCTS,
					contactoEmergencia: formulario.contactoEmergencia,
				};

				const formData = new FormData();
				formData.append(
					"informacionPersonal",
					new Blob([JSON.stringify(informacionPersonal)], {
						type: "application/json",
					})
				);

				formData.append("firmaHolografa", firmaHolografa);
				formData.append("huellaDactilar", huellaDactilar);
				formData.append("fotoPersonal", fotoPersonal);

				try {
					await OnboardingService.completarInformacionPersonal(formData);
					message.success("Información personal registrada correctamente.");
					actualizarEstadoFormulario();
					setLoading(false);
					if (
						getUsuarioAutenticado().empresaSeleccionada.colaborador.estado === "ACTIVO" &&
						getUsuarioAutenticado().empresaSeleccionada.colaborador.informacionPersonalCompletado
					) {
						window.location.reload();
					}
					setPasoActual(1);
					//history.push("/mis-documentos");
				} catch (error) {
					message.error("No se pudo guardar su información personal. Intente nuevamente");
					setLoading(false);
				}
			})
			.catch((info) => {
				console.error("Validate Failed:", info);
				message.error("Complete los datos obligatorios.");
			});
	};

	return (
		<Row gutter={16}>
			<Col span={24}>
				<div style={{ display: "flex", justifyContent: "center" }}>
					<Button
						type="primary"
						size="large"
						style={{ borderRadius: "16px", padding: "0px 50px" }}
						loading={loading}
						onClick={onClickBtnContinuar}
					>
						Continuar <RightOutlined />
					</Button>
				</div>
			</Col>
		</Row>
	);
};

const DatosPersonales = () => {
	const [firmaHolografa, setFirmaHolografa] = useAtom(firmaHolografaAtom);
	const [huellaDactilar, setHuellaDactilar] = useAtom(huellaDactilarAtom);
	const [fotoPersonal, setFotoPersonal] = useAtom(fotoPersonalAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const ismaccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const ismaccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;
	const isPSP = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20601257280" ? true : false;
	const propsFirma = {
		onRemove: (_) => {
			setFirmaHolografa([]);

			return {
				firmaHolografa: [],
			};
		},
		beforeUpload: (file) => {
			if (file.type !== "image/png" && file.type !== "image/jpeg") {
				message.error(`${file.name} no es un archivo png ó jpeg`);
			} else {
				setFirmaHolografa(file);
				return false;
			}
		},
		firmaHolografa,
	};

	const propsHuella = {
		onRemove: (_) => {
			setHuellaDactilar([]);

			return {
				huellaDactilar: [],
			};
		},
		beforeUpload: (file) => {
			if (file.type !== "image/png" && file.type !== "image/jpeg") {
				message.error(`${file.name} no es un archivo png ó jpeg`);
			} else {
				setHuellaDactilar(file);
				return false;
			}
		},
		huellaDactilar,
	};

	const propsFoto = {
		onRemove: (_) => {
			setFotoPersonal([]);

			return {
				fotoPersonal: [],
			};
		},
		beforeUpload: (file) => {
			if (file.type !== "image/png" && file.type !== "image/jpeg") {
				message.error(`${file.name} no es un archivo png ó jpeg`);
			} else {
				setFotoPersonal(file);
				return false;
			}
		},
		fotoPersonal,
	};

	return (
		<>
			<Form.Item
				label="Tipo Documento Identidad"
				name={["datosPersonales", "tipoDocumentoIdentidad"]}
				rules={[
					{
						required: true,
						message: "Selecciona el tipo de documento",
					},
				]}
			>
				<Select allowClear>
					<Select.Option value={TipoDocumentoIdentidad.DNI.valor}>
						{TipoDocumentoIdentidad.DNI.nombre}
					</Select.Option>
					<Select.Option value={TipoDocumentoIdentidad.CARNET_EXTRANJERIA.valor}>
						{TipoDocumentoIdentidad.CARNET_EXTRANJERIA.descripcion}
					</Select.Option>
				</Select>
			</Form.Item>

			<Form.Item
				label="Número de Documento Identidad"
				name={["datosPersonales", "numeroDocumentoIdentidad"]}
				rules={[
					{
						required: true,
						message: "Escribe el número de tu documento de identidad",
						whitespace: true,
					},
				]}
			>
				<Input autoComplete="off" />
			</Form.Item>

			<Form.Item
				label="Apellido Paterno"
				name={["datosPersonales", "apellidoPaterno"]}
				rules={[
					{
						required: true,
						message: "Ingresa tu apellido paterno",
					},
				]}
			>
				<Input />
			</Form.Item>

			<Form.Item
				label="Apellido Materno"
				name={["datosPersonales", "apellidoMaterno"]}
				rules={[
					{
						required: true,
						message: "Ingresa tu apellido materno",
					},
				]}
			>
				<Input />
			</Form.Item>

			<Form.Item
				label="Nombres"
				name={["datosPersonales", "nombres"]}
				rules={[
					{
						required: true,
						message: "Ingresa tus nombres",
					},
				]}
			>
				<Input />
			</Form.Item>

			<Form.Item
				label="Género"
				name={["datosPersonales", "genero"]}
				rules={[
					{
						required: true,
						message: "Selecciona tu género",
					},
				]}
			>
				<Select>
					<Select.Option value="FEMENINO">FEMENINO</Select.Option>
					<Select.Option value="MASCULINO">MASCULINO</Select.Option>
				</Select>
			</Form.Item>

			<Form.Item
				label="Estado Civil"
				name={["datosPersonales", "estadoCivil"]}
				rules={[
					{
						required: true,
						message: "Selecciona tu estado civil",
					},
				]}
			>
				<Select>
					<Select.Option value="SOLTERO">SOLTERO</Select.Option>
					<Select.Option value="CASADO">CASADO</Select.Option>
					<Select.Option value="CONVIVIENTE">CONVIVIENTE</Select.Option>
					<Select.Option value="DIVORCIADO">DIVORCIADO</Select.Option>
					<Select.Option value="VIUDO">VIUDO</Select.Option>
				</Select>
			</Form.Item>

			<Form.Item
				label="Correo Electrónico"
				name={["datosPersonales", "correoElectronico"]}
				rules={[
					{
						required: true,
						message: "Ingresa tu correo electrónico",
					},
				]}
			>
				<Input />
			</Form.Item>

			<Form.Item label="Celular" name={["datosPersonales", "celular"]}>
				<Input />
			</Form.Item>

			<Form.Item
				name="firmaHolografa"
				label="Firma Hológrafa"
				valuePropName="firmaHolografa"
				rules={[
					{
						required: true,
						message: "Suba una imágen de su firma hológrafa",
					},
				]}
			>
				<Upload {...propsFirma} maxCount={1} accept={["image/*"]}>
					<Button block>Subir Firma Hológrafa</Button>
				</Upload>
			</Form.Item>

			{!ismaccaferriDePeru && !ismaccaferriConstruction && (
				<Form.Item
					name="huellaDactilar"
					label="Huella Dactilar"
					valuePropName="huellaDigital"
					rules={[
						{
							required: true,
							message: "Suba una imágen de su huella dactilar",
						},
					]}
				>
					<Upload {...propsHuella} maxCount={1} accept={["image/*"]}>
						<Button block>Subir Huella Dactilar</Button>
					</Upload>
				</Form.Item>
			)}

			<Form.Item
				name="fotoColaborador"
				label="Foto personal"
				valuePropName="fotoColaborador"
				rules={[
					{
						required: true,
						message: "Suba una foto.",
					},
				]}
			>
				<Upload {...propsFoto} maxCount={1} accept={["image/*"]}>
					<Button block icon={<UploadOutlined />}>
						Subir Foto
					</Button>
				</Upload>
			</Form.Item>
		</>
	);
};

const DatosNacimiento = () => {
	return (
		<>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="País" name={["datosNacimiento", "pais"]} rules={[{ required: true }]}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Nacionalidad"
						name={["datosNacimiento", "nacionalidad"]}
						rules={[{ required: true }]}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item
						label="Fecha Nacimiento"
						name={["datosNacimiento", "fechaNacimiento"]}
						rules={[{ required: true }]}
					>
						<DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Departamento"
						name={["datosNacimiento", "departamento"]}
						rules={[{ required: true }]}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Provincia" name={["datosNacimiento", "provincia"]} rules={[{ required: true }]}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Distrito" name={["datosNacimiento", "distrito"]} rules={[{ required: true }]}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={24}>
					<Form.Item label="Direccion" name={["datosNacimiento", "direccion"]} rules={[{ required: true }]}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};

const DomicilioActual = () => {
	return (
		<>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item
						label="Direccion"
						name={["domicilioActual", "direccion"]}
						rules={[
							{
								required: true,
								message: "Ingresa una dirección",
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Departamento"
						name={["domicilioActual", "departamento"]}
						rules={[
							{
								required: true,
								message: "Ingresa el departamento",
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item
						label="Provincia"
						name={["domicilioActual", "provincia"]}
						rules={[
							{
								required: true,
								message: "Ingresa la provincia",
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Distrito"
						name={["domicilioActual", "distrito"]}
						rules={[
							{
								required: true,
								message: "Ingresa el distrito",
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};

const RegimenPensionario = ({ form }) => {
	const tipoRegimenPensionario = Form.useWatch(["regimenPensionario", "tipo"], form);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;
	return !isExplomin ? (
		<>
			<Form.Item label="Tipo" name={["regimenPensionario", "tipo"]} rules={[{ required: true }]}>
				<Select allowClear>
					<Select.Option value="AFP">AFP</Select.Option>
					<Select.Option value="ONP">ONP</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item
				label="CUSPP AFP"
				name={["regimenPensionario", "cusppAfp"]}
				rules={[{ required: tipoRegimenPensionario === "AFP" ? true : false }]}
			>
				<Input disabled={tipoRegimenPensionario !== "AFP"} />
			</Form.Item>
			<Form.Item
				label="Nombre AFP"
				name={["regimenPensionario", "nombreAfp"]}
				rules={[{ required: tipoRegimenPensionario === "AFP" ? true : false }]}
			>
				<Input disabled={tipoRegimenPensionario !== "AFP"} />
			</Form.Item>
		</>
	) : (
		<>
			<Form.Item label="Tipo" name={["regimenPensionario", "tipo"]} rules={[{ required: true }]}>
				<Select allowClear>
					<Select.Option value="AFP">AFP</Select.Option>
					<Select.Option value="ONP">ONP</Select.Option>
				</Select>
			</Form.Item>
		</>
	);
};

const CuentaBancaria = ({ form }) => {
	const existeCuenta = Form.useWatch(["cuentaHaberes", "exist"], form);

	return (
		<>
			<Form.Item
				name={["cuentaHaberes", "exist"]}
				rules={[{ required: true, message: "Por favor seleccione una opcion" }]}
			>
				<Radio.Group>
					<Radio value={true}>Tengo Cuenta</Radio>
					<Radio value={false}>Crear Cuenta</Radio>
				</Radio.Group>
			</Form.Item>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Banco" name={["cuentaHaberes", "banco"]} rules={[{ required: existeCuenta }]}>
						<Input disabled={!existeCuenta} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Moneda"
						name={["cuentaHaberes", "tipoMoneda"]}
						rules={[{ required: existeCuenta }]}
					>
						<Input disabled={!existeCuenta} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Cuenta"
						name={["cuentaHaberes", "nroCuenta"]}
						rules={[{ required: existeCuenta }]}
					>
						<Input disabled={!existeCuenta} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Cuenta CCI"
						name={["cuentaHaberes", "nroCuentaCCI"]}
						rules={[{ required: existeCuenta }]}
					>
						<Input disabled={!existeCuenta} />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};

const CuentaCTS = ({ form }) => {
	const existeCuenta = Form.useWatch(["cuentaCTS", "exist"], form);

	return (
		<>
			<Form.Item
				name={["cuentaCTS", "exist"]}
				rules={[{ required: true, message: "Por favor seleccione una opcion" }]}
			>
				<Radio.Group style={{ alignItems: "center" }}>
					<Radio value={true}>Tengo Cuenta</Radio>
					<Radio value={false}>Crear Cuenta</Radio>
				</Radio.Group>
			</Form.Item>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Banco" name={["cuentaCTS", "banco"]} rules={[{ required: existeCuenta }]}>
						<Input disabled={!existeCuenta} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Moneda" name={["cuentaCTS", "tipoMoneda"]} rules={[{ required: existeCuenta }]}>
						<Input disabled={!existeCuenta} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Cuenta" name={["cuentaCTS", "nroCuenta"]} rules={[{ required: existeCuenta }]}>
						<Input disabled={!existeCuenta} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Cuenta CCI"
						name={["cuentaCTS", "nroCuentaCCI"]}
						rules={[{ required: existeCuenta }]}
					>
						<Input disabled={!existeCuenta} />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};

const ContactoEmergencia = () => {
	return (
		<>
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item label="Nombres" name={["contactoEmergencia", "nombres"]}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Telefono" name={["contactoEmergencia", "telefono"]}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};

const DatosPersonalesColaboradoraActivo = () => {
	const [firmaHolografa, setFirmaHolografa] = useAtom(firmaHolografaAtom);
	const [huellaDactilar, setHuellaDactilar] = useAtom(huellaDactilarAtom);
	const [fotoPersonal, setFotoPersonal] = useAtom(fotoPersonalAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;
	const ismaccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const ismaccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;
	const isPSP = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20601257280" ? true : false;
	const propsFirma = {
		onRemove: (_) => {
			setFirmaHolografa([]);

			return {
				firmaHolografa: [],
			};
		},
		beforeUpload: (file) => {
			if (file.type !== "image/png" && file.type !== "image/jpeg") {
				message.error(`${file.name} no es un archivo png ó jpeg`);
			} else {
				setFirmaHolografa(file);
				return false;
			}
		},
		firmaHolografa,
	};

	const propsHuella = {
		onRemove: (_) => {
			setHuellaDactilar([]);

			return {
				huellaDactilar: [],
			};
		},
		beforeUpload: (file) => {
			if (file.type !== "image/png" && file.type !== "image/jpeg") {
				message.error(`${file.name} no es un archivo png ó jpeg`);
			} else {
				setHuellaDactilar(file);
				return false;
			}
		},
		huellaDactilar,
	};

	const propsFoto = {
		onRemove: (_) => {
			setFotoPersonal([]);

			return {
				fotoPersonal: [],
			};
		},
		beforeUpload: (file) => {
			if (file.type !== "image/png" && file.type !== "image/jpeg") {
				message.error(`${file.name} no es un archivo png ó jpeg`);
			} else {
				setFotoPersonal(file);
				return false;
			}
		},
		fotoPersonal,
	};

	return (
		<>
			<Form.Item
				name="firmaHolografa"
				label={
					<span>
						<span style={{ color: "#ff4d4f", marginRight: "4px" }}>*</span>
						Firma Hológrafa
					</span>
				}
				valuePropName="firmaHolografa"
				validateStatus={firmaHolografa && firmaHolografa.length == 0 ? "error" : ""}
				help={firmaHolografa && firmaHolografa.length == 0 && "Firma hológrafa obligatoria"}
				rules={[
					{
						validator: async (rule, value) => {
							if (!firmaHolografa || firmaHolografa.length === 0) {
								return Promise.reject("Firma hológrafa obligatoria");
							}
							return Promise.resolve();
						},
					},
				]}
			>
				<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
					<Upload {...propsFirma} maxCount={1} accept={["image/*"]}>
						<Button block icon={<UploadOutlined />}>
							Subir Firma Hológrafa
						</Button>
					</Upload>
				</ImgCrop>
			</Form.Item>

			{!ismaccaferriDePeru && !ismaccaferriConstruction && (
				<Form.Item
					name="huellaDactilar"
					label={
						<span>
							<span style={{ color: "#ff4d4f", marginRight: "4px" }}>*</span>
							Huella Dactilar
						</span>
					}
					valuePropName="huellaDigital"
					validateStatus={huellaDactilar && huellaDactilar.length == 0 ? "error" : ""}
					help={huellaDactilar && huellaDactilar.length == 0 && "Huella dactilar obligatoria"}
					rules={[
						{
							validator: async (rule, value) => {
								if (!huellaDactilar || huellaDactilar.length === 0) {
									return Promise.reject("Huella dactilar obligatoria");
								}
								return Promise.resolve();
							},
						},
					]}
				>
					<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
						<Upload {...propsHuella} maxCount={1} accept={["image/*"]}>
							<Button block icon={<UploadOutlined />}>
								Subir Huella Dactilar
							</Button>
						</Upload>
					</ImgCrop>
				</Form.Item>
			)}

			{!getUsuarioAutenticado().empresaSeleccionada?.colaborador?.urlFoto && !isExplomin && (
				<Form.Item
					name="fotoColaborador"
					label={
						<span>
							<span style={{ color: "#ff4d4f", marginRight: "4px" }}>*</span>
							Foto Personal
						</span>
					}
					valuePropName="fotoColaborador"
					validateStatus={fotoPersonal && fotoPersonal.length == 0 ? "error" : ""}
					help={fotoPersonal && fotoPersonal.length == 0 && "Suba una imagen de su foto"}
					rules={[
						{
							validator: async (rule, value) => {
								if (!fotoPersonal || fotoPersonal.length === 0) {
									return Promise.reject("Suba una imagen de su foto");
								}
								return Promise.resolve();
							},
						},
					]}
				>
					<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
						<Upload {...propsFoto} maxCount={1} accept={["image/*"]}>
							<Button block icon={<UploadOutlined />}>
								Subir Foto
							</Button>
						</Upload>
					</ImgCrop>
				</Form.Item>
			)}

			{!getUsuarioAutenticado().empresaSeleccionada?.colaborador?.urlFoto && isExplomin && (
				<Form.Item
					name="fotoColaborador"
					label="Foto personal"
					valuePropName="fotoColaborador"
					rules={[
						{
							required: false,
						},
					]}
				>
					<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
						<Upload {...propsFoto} maxCount={1} accept={["image/*"]}>
							<Button block icon={<UploadOutlined />}>
								Subir Foto
							</Button>
						</Upload>
					</ImgCrop>
				</Form.Item>
			)}
		</>
	);
};

export default FormularioInformacionPersonal;
