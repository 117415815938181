import React, { useEffect, useContext } from "react";
import { Container } from "components/Container";
import { VisorPdf } from "../VisorPdf";
import { ModalFirmar } from "../ModalFirmar";
import { ModalExito } from "../ModalExito";
import { ListaDocumentos } from "./componentes/ListaDocumentos";
import { VisorFormulario } from "./componentes";
import { ModalConsultar } from "../VisorPdf/Header/ModalConsultar";
import { Alert } from "antd";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import * as atomsConsulta from "../../../hooks/consultas/ConsultasStorage";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import { ModalMensajeAlerta } from "../ModalMensajeAlerta";
import { ModalSubirArchivo } from "../ModalSubirArchivo/ModalSubirArchivo";
import {
	loadingPdfAtom,
} from "pages/mis-documentos/componentes/MisDocumentosWeb/MisDocumentosWebStorage";

const MisDocumentosWeb = () => {
	const [totalRespuestasUsuario, setTotalRespuestasUsuario] = useAtom(atomsConsulta.totalRespuestasUsuarioAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const [loadingPdf, setLoadingPdf] = useAtom(loadingPdfAtom);

	useEffect(() => {
		const cargarDatos = () => {
			obtenerNumeroDetalleNoVisualizadas();
		};
		cargarDatos();
	}, []);

	const obtenerNumeroDetalleNoVisualizadas = async () => {
		const consultas = await PortalColaboradorService.listaConsultas(
			getUsuarioAutenticado().empresaSeleccionada.id,
			getUsuarioAutenticado().numeroDocumentoIdentidad
		);
		let listaConsultasId = [];
		if (consultas.length > 0) {
			consultas.map((consulta) => {
				listaConsultasId.push(consulta.id);
			});
			const respuestasXConsulta = await PortalColaboradorService.listaNumeroRespuestas(
				listaConsultasId,
				"respuestaUsuario"
			);
			let totalRespuestas = 0;
			respuestasXConsulta.length > 0 &&
				respuestasXConsulta.map((respuesta) => {
					respuesta.numeroRespuestas > 0 && totalRespuestas++;
				});
			setTotalRespuestasUsuario(totalRespuestas);
		}
	};
	return (
		<React.Fragment>
			{totalRespuestasUsuario > 0 && (
				<React.Fragment>
					<Alert
						message="Estimado Colaborador, tiene notificaciones pendientes de revisar."
						type="info"
						showIcon
					/>
					<br />
				</React.Fragment>
			)}

			<Container titulo="Mis Documentos">
				<ListaDocumentos />
				<VisorFormulario loadingPdf={loadingPdf}/>
				<VisorPdf />
				<ModalFirmar />
				<ModalExito />
				<ModalSubirArchivo />
				<ModalConsultar />
				<ModalMensajeAlerta />
			</Container>
		</React.Fragment>
	);
};

export default MisDocumentosWeb;
