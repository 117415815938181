import React, { useEffect, useRef, useContext } from "react";
import { Button } from "antd";
import { ListaTareasPendientes } from "./componentes";
import { VisorFormulario } from "components/common";
import { useAtomValue, useSetAtom } from "jotai";
import {
	datosFormularioAtom,
	tareaPendienteSeleccionadaAtom,
	visibleModalFirmarAtom,
} from "pages/tareas-pendientes/TareasPendientesStorage";
import styled from "@emotion/styled";
import { FormProvider, useForm } from "react-hook-form";
import { TareasPendientesService } from "services";
import { Plataforma } from "enum/Plataforma";
import { ModalFirmar } from "../common/componentes";

import { SecurityContext } from "context/SecurityContextProvider";

const TareasPendientesPageWeb = () => {
	const setVisibleModalFirmar = useSetAtom(visibleModalFirmarAtom);
	const setDatosFormulario = useSetAtom(datosFormularioAtom);
	const tareaPendienteSeleccionada = useAtomValue(tareaPendienteSeleccionadaAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const frmVisor = useForm();

	const onSubmit = async (datosFormulario) => {
		setDatosFormulario({
			tareaPendienteId: tareaPendienteSeleccionada.id,
			datos: datosFormulario,
			plataforma: Plataforma.WEB,
		});
		setVisibleModalFirmar(true);
	};

	const onClickBtnFirmar = () => {
		frmVisor.handleSubmit(onSubmit)();
	};

	return (
		<Container>
			<ListaTareasPendientes />
			<ContainerVisor>
				{tareaPendienteSeleccionada && (
					<Header>
						<Acciones>
							<Button type="primary" onClick={onClickBtnFirmar}>
								Firmar Documento
							</Button>
						</Acciones>
					</Header>
				)}
				<Body withHeader={tareaPendienteSeleccionada ? true : false}>
					<FormProvider {...frmVisor}>
						<VisorFormulario
							type={"form"}
							urlPdf={tareaPendienteSeleccionada?.urlPlantillaPdfFirmada}
							form={frmVisor}
							componentes={tareaPendienteSeleccionada?.componentes}
							variablesGlobales={tareaPendienteSeleccionada?.variablesGlobales}
							datosFormulario={tareaPendienteSeleccionada?.datosFormulario}
							urlFirmaHolografa={tareaPendienteSeleccionada?.urlFirmaHolografa}
							urlHuellaDactilar={tareaPendienteSeleccionada?.urlHuellaDactilar}
						/>
					</FormProvider>
				</Body>
			</ContainerVisor>
			<ModalFirmar />
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-wrap: "wrap";
	justify-content: space-between;
	height: calc(100vh - 125px);
`;

const ContainerVisor = styled.div`
	flex: 1 1 70%;
	margin: 10px;
`;

const Header = styled.div`
	min-height: 50px;
	max-height: 50px;
	display: flex;
	background: white;
	padding: 0px 10px;
	border: 1px solid #ccc;
	border-bottom: 0px;
`;

// const Body = styled.div`
// 	height: calc(100% - 50px);
// `;

const Body = styled.div`
	height: ${(props) => (props.withHeader === true ? "calc(100% - 50px)" : "100%")};
`;

const Acciones = styled.div`
	flex: 1 1 50%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export default TareasPendientesPageWeb;
