import React from "react";
import { useMediaQuery } from "react-responsive";
import { VacacionesPageMovil, VacacionesPageWeb } from "./componentes";
import { Provider as JotaiProvider } from "jotai";

const SolicitudAusenciaPage = () => {
	const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
	const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });
	return (
		<JotaiProvider>
			{esMovil && <VacacionesPageMovil />}
			{esDesktop && <VacacionesPageWeb />}
		</JotaiProvider>
	);
};

export default SolicitudAusenciaPage;
