import React from 'react';

const LoginActiveDirectoryValidationPage = () => {
    return (
        <div>
            Validar Token!!!
        </div>
    );
};

export default LoginActiveDirectoryValidationPage;