import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Badge } from "antd";
import { ItemTareaPendiente } from "pages/tareas-pendientes/componentes/common";
import { useAtom, useSetAtom } from "jotai";
import {
	tareaPendienteSeleccionadaAtom,
	tareaSeleccionadaIdAtom,
} from "pages/tareas-pendientes/TareasPendientesStorage";
import { useListarTareasPendientes } from "pages/tareas-pendientes/componentes/common/hooks";
import { TareasPendientesService } from "services";

const ListaTareasPendientes = () => {
	const [tareaSeleccionadaId, setTareaSeleccionadaId] = useAtom(tareaSeleccionadaIdAtom);
	const setTareaPendienteSeleccionada = useSetAtom(tareaPendienteSeleccionadaAtom);
	const { tareasPendientes } = useListarTareasPendientes();
	//const [urlFirma, setUrlFirma] = useState(undefined);

	const onSeleccionar = (id) => {
		if (id !== tareaSeleccionadaId) {
			setTareaSeleccionadaId(id);
		}
	};

	useEffect(() => {
		const getInformacionTareaPendiente = async () => {
			if (tareaSeleccionadaId) {
				const respuesta = await TareasPendientesService.buscarPorId(tareaSeleccionadaId);
				setTareaPendienteSeleccionada(respuesta);
			}
		};

		getInformacionTareaPendiente();
	}, [tareaSeleccionadaId]);

	return (
		<Container>
			<Titulo>
				<Badge status="warning" text="Tareas Pendientes" />
			</Titulo>
			<Items>
				{tareasPendientes &&
					tareasPendientes.map((item) => {
						return (
							<ItemTareaPendiente
								key={item.id}
								id={item.id}
								tipo={item.tipo}
								descripcion={item.descripcion}
								fecha={item.fechaRegistro}
								checked={tareaSeleccionadaId == item.id}
								onSeleccionar={onSeleccionar}
							/>
						);
					})}
			</Items>
		</Container>
	);
};

const Container = styled.div`
	border: 1px solid #ccc;
	flex: 1 1 25%;
	margin: 10px;
	background-color: white;
`;

const Titulo = styled.div`
	flex: 1 1 20%;
	padding: 10px;
	border-bottom: 1px solid #ccc;
`;

const Items = styled.div`
	padding: 10px;
	overflow: auto;
	max-height: calc(100% - 45px);
`;

export default ListaTareasPendientes;
