import React from "react";

import { Row, Col, Card, Divider } from "antd";

export function ContainerCrear({
  icono,
  titulo,
  descripcion,
  colSpan = 24,
  children,
}) {
  return (
    <Card>
      <Row justify="center">
        <Col span={colSpan}>
          <div className="container-crear-titulo">{titulo}</div>
          <div className="container-crear-descripcion">{descripcion}</div>
          <Divider />
          {children}
        </Col>
      </Row>
    </Card>
  );
}
