import {Divider, Empty, Form, Spin, Steps, Result, Button, Typography, Upload, message, Tag } from "antd";
import { SecurityContext } from "context/SecurityContextProvider";
import React, { useState } from "react";
import { useContext } from "react";
import {
	FormularioInformacionPersonal,
	ListaDocumentos,
	ModalFirmar,
	ModalOmitir,
} from "../common/componentes";
import { useAtom, useAtomValue } from "jotai";
import {
	loadingAtom, pasoActualAtom, estadoFirmaOnboardingAtom, estadoHuellaOnboardingAtom,
	estadoFirmaEnviadoValidacionOnboardingAtom, estadoHuellaEnviadoValidacionOnboardingAtom,
informacionPersonalCompletadoAtom} from "pages/onboarding/OnboardingStore";
import {
	useCargarInformacionPersonal,
	useStatusOnboarding,
} from "../common/hooks";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import { CompletarDocumentos } from "./componentes";
import { LoadingOutlined, SyncOutlined, CloseCircleOutlined,CheckOutlined } from "@ant-design/icons";
import LoginService from "services/LoginService";
import ImgCrop from "antd-img-crop";
import { Headers } from "enum";
import { OnboardingService } from "services";

const OnboardingPageWeb = () => {
	const { Paragraph, Text } = Typography;
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;
	const { form } = useCargarInformacionPersonal();
	const { statusOnboarding } = useStatusOnboarding();
	const [loading, setLoading] = useAtom(loadingAtom);
	const pasoActual = useAtomValue(pasoActualAtom);
	const [estadoFirma, setEstadoFirma] = useAtom(estadoFirmaOnboardingAtom);
	const [estadoHuella, setEstadoHuella] = useAtom(estadoHuellaOnboardingAtom);
	const [informacionPersonalCompletado, setInformacionPersonalCompletado] = useAtom(informacionPersonalCompletadoAtom);

	const [estadoFirmaEnviadoValidacion, setEstadoFirmaEnviadoValidacion] = useAtom(estadoFirmaEnviadoValidacionOnboardingAtom);
	const [estadoHuellaEnviadoValidacion, setEstadoHuellaEnviadoValidacion] = useAtom(estadoHuellaEnviadoValidacionOnboardingAtom);
	const history = useHistory();

	useEffect(() => {
		const verificarStatusOnboarding = () => {
			if (!statusOnboarding || !statusOnboarding?.completado) {
				setLoading(false);
			}
		};
		verificarStatusOnboarding();
	}, [statusOnboarding]);

	const propsFirma = {
		name: "file",
		multiple: false,
		action: process.env.REACT_APP_ENDPOINT + "colaborador/actualizar/estado-firma-holografa",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			[Headers.TIPO_USUARIO]: "COLABORADOR",
            [Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
		},
		data: {
			colaborador: new Blob(
				[
					JSON.stringify({
						numeroDocumentoIdentidad: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
					}),
				],
				{ type: "application/json" }
			),
		},
		onChange(info) {
			const { status } = info.file;
			if (status === "done") {
				message.success(`${info.file.name} se subió exitosamente.`);
				//cargarColaborador();
				setEstadoFirmaEnviadoValidacion(true);
			} else if (status === "error") {
				message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
			}
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};

	const propsHuella = {
		name: "file",
		multiple: false,
		action: process.env.REACT_APP_ENDPOINT + "colaborador/actualizar/estado-huella",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
			[Headers.TIPO_USUARIO]: "COLABORADOR",
            [Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
		},
		data: {
			colaborador: new Blob(
				[
					JSON.stringify({
						numeroDocumentoIdentidad: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad,
					}),
				],
				{ type: "application/json" }
			),
		},
		onChange(info) {
			const { status } = info.file;
			if (status === "done") {
				message.success(`${info.file.name} se subió exitosamente.`);
				//setEstadoHuella("PENDIENTE");
				setEstadoHuellaEnviadoValidacion(true);
			} else if (status === "error") {
				message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
			}
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};

	async function handleOnRefrescar() {
		console.log("handleOnRefrescar::");
		const statusOnboarding = await OnboardingService.obtenerStatusOnboarding();
		setEstadoFirma(statusOnboarding.estadoImagenFirma);
		setEstadoHuella(statusOnboarding.estadoImagenHuella);
		
		setEstadoFirmaEnviadoValidacion(false);
		setEstadoHuellaEnviadoValidacion(false);
	}

	if (isExplomin) { 

		if (informacionPersonalCompletado && (estadoFirma=="RECHAZADO" || estadoHuella == "RECHAZADO" || estadoFirma=="NO_CARGADA" || estadoHuella == "NO_CARGADA")) { 
			return (
					<Result
						status="error"
						title="Archivos rechazados"
						// subTitle="Please check and modify the following information before resubmitting."
						extra={[
						<Button type="primary" key="btnRechazar" onClick={handleOnRefrescar}><SyncOutlined /> Refrescar</Button>
						]}
					>
						<div className="desc">
						<Paragraph>
							<Text
							strong
							style={{
								fontSize: 16,
							}}
							>
							Actualizar los siguientes archivos para su validación:
							</Text>
						</Paragraph>
						{/* <Paragraph>
							<CloseCircleOutlined className="site-result-demo-error-icon" /> Your account has been
							frozen. <a>Thaw immediately &gt;</a>
						</Paragraph> */}
						{(estadoFirma == "RECHAZADO" || estadoFirma=="NO_CARGADA") && (
							<React.Fragment>
								{!estadoFirmaEnviadoValidacion && (
									<Paragraph>
										<CloseCircleOutlined style={{ color: "red" }} />
										<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
												<Upload {...propsFirma} maxCount={1} accept={["image/*"]}>
													<Button block type="link">
														Cambiar Firma Hológrafa
													</Button>
												</Upload>
										</ImgCrop>
									</Paragraph>
								)} 
								{estadoFirmaEnviadoValidacion && (
									<Paragraph>
										<Tag color="green">
											<CheckOutlined /> Firma enviada para su validación ...
										</Tag>
									</Paragraph>
								)} 
							</React.Fragment>
							
						)}
						
						{(estadoHuella == "RECHAZADO" || estadoHuella == "NO_CARGADA")&& (
							<React.Fragment>
								{!estadoHuellaEnviadoValidacion && (
									<Paragraph>
											<CloseCircleOutlined style={{ color: "red" }} />
											<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
													<Upload {...propsHuella} maxCount={1} accept={["image/*"]}>
														<Button block type="link">
															Cambiar Huella Dactilar
														</Button>
													</Upload>
												</ImgCrop>
									</Paragraph>
								)}
								{estadoHuellaEnviadoValidacion && (
									<Paragraph>
										<Tag color="green">
											<CheckOutlined /> Huella enviada para su validación ...
										</Tag>
									</Paragraph>
								)} 
							</React.Fragment>
						) }
						
						</div>
					</Result>
				);
		}

		if (informacionPersonalCompletado && (estadoFirma=="PENDIENTE" || estadoHuella == "PENDIENTE")) { 
			return (
					<Result
						status="404"
						title="En proceso de validación de su firma y huella cargada"
						subTitle="Los encargados de la validación son del área de Reclutamiento y Selección."
						extra={<Button type="primary" onClick={handleOnRefrescar}><SyncOutlined /> Refrescar</Button>}
					/>
				);
		}
	}
	

	if (loading) {
		return (
			<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
			</Spin>
		);
	}

	return (
		<div>
			<div
				style={{
					backgroundColor: "white",
					borderRadius: "15px",
					minHeight: "60px",
					padding: pasoActual == 0 ? "0px 1.5%" : "0px 30%",
					margin: pasoActual == 0 ? "0px 20%" : "0px 10px 0px 20px ",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Steps
					type="navigation"
					current={pasoActual}
					items={[
						{
							title: "Información Personal",
						},
						getUsuarioAutenticado().empresaSeleccionada.colaborador
							.estado === "ONBOARDING" && {
							title: "Subir Documentos",
						},
					]}
				/>
			</div>
			{pasoActual == 0 && (
				<div
					id="step-informacion-personal"
					style={{
						borderRadius: "15px",
						backgroundColor: "white",
						margin: "0px 20%",
						marginTop: "20px",
						padding: "20px 30px",
					}}
				>
					<FormularioInformacionPersonal formulario={form} />
				</div>
			)}
			{pasoActual == 1 && <CompletarDocumentos />}
			<ModalFirmar />
			<ModalOmitir />
		</div>
	);
};

const Container = styled.div`
	display: flex;
	flex-wrap: "wrap";
	justify-content: space-between;
	height: calc(100vh - 190px);
`;

const ContainerDocumentos = styled.div`
	flex: 1 1 30%;
	margin: 10px;
	overflow: auto;
`;

const ContainerVisor = styled.div`
	border: 1px solid #ccc;
	flex: 1 1 70%;
	margin: 10px;
	background-color: white;
`;

export default OnboardingPageWeb;
