import React, { useState, useContext } from "react";
import { Drawer, Button, Divider } from "antd";
import {
	FileTextOutlined,
	AppstoreOutlined,
	ImportOutlined,
	ContainerOutlined,
	CalendarOutlined,
	UsergroupAddOutlined,
	ProfileOutlined,
	BarsOutlined,
	UserOutlined,
	EditOutlined,
	FolderOpenOutlined,
	MailOutlined
} from "@ant-design/icons";
import { SecurityContext } from "../../../../context/SecurityContextProvider";
import { MenuItemMovil } from "./components/MenuMovilItem";
import { useHistory } from "react-router-dom";

import "./index.css";
import { VersionApiColaborador } from "../../../../enum/VersionApiColaborador";
import { useEffect } from "react";

export function MenuMovil({ opciones }) {
	const page = window.location.pathname.split("/")[1];
	const [menuActivo, setMenuActivo] = useState(page === "mis-documentos" ? "bandejaEntrada" : page);
	const [visibleMenuPerfil, setVisibleMenuPerfil] = useState(false);
	const { getVersionApiColaborador, cerrarSesion, getUsuarioAutenticado } = useContext(SecurityContext);

	const isAceros = getUsuarioAutenticado().empresaSeleccionada.razonSocial.toUpperCase().includes("ACEROS CHILCA SAC")
		? true
		: false;

	useEffect(() => {
		//Nota: Se usa para marcar activo el menu principal
		const ruta = window.location.pathname;
		switch (ruta) {
			case "/vacaciones/solicitudes":
				setMenuActivo("portal-colaborador.vacaciones");
				break;
			case "/mis-documentos":
				//setMenuActivo("bandejaEntrada");
				setMenuActivo("portal-colaborador.mis-documentos");
				break;
			case "/bandejaConsulta":
				setMenuActivo("bandejaConsulta");
				break;
			default:
				setMenuActivo(ruta);
		}
	}, []);

	let history = useHistory();

	function onClickMenuItem(opcion) {
		if (opcion === "bandejaEntrada") {
			setMenuActivo(opcion);
			if (getVersionApiColaborador() === VersionApiColaborador.v1) {
				history.push("/bandejaEntrada");
			} else {
				history.push("/mis-documentos");
			}
		} else if (opcion === "portal-colaborador.mis-documentos") {
			setMenuActivo(opcion);
			history.push("/mis-documentos");
		} else if (opcion === "portal-colaborador.v3.mis-pendientes") {
			setMenuActivo(opcion);
			history.push("/v3/mis-pendientes");
		} else if (opcion === "portal-colaborador.v3.mis-documentos") {
			setMenuActivo(opcion);
			history.push("/v3/mis-documentos");
		} else if (opcion === "bandejaConsulta") {
			// setMenuActivo(opcion);
			// if (
			// 	getVersionApiColaborador() === VersionApiColaborador.v1 ||
			// 	getVersionApiColaborador() === VersionApiColaborador.v2
			// ) {
			history.push("/bandejaConsulta");
			// }
		} else if (opcion === "portal-colaborador.vacaciones") {
			setMenuActivo(opcion);
			history.push("/vacaciones/solicitudes");
		} else if (opcion === "perfil") {
			setMenuActivo(opcion);
			history.push("/movil/perfil");
		} else if (opcion === "menu") {
			setVisibleMenuPerfil(true);
		}
	}

	function onCloseMenuPerfil() {
		setVisibleMenuPerfil(false);
	}

	function onClickSalir() {
		setVisibleMenuPerfil(false);
		cerrarSesion();
	}
	function tieneAcceso(codigo) {
		return opciones?.includes(codigo);
	}

	function mostrarMenuItemMovil(identificador, nombre, icono, menuActivo, evento) {
		if (tieneAcceso(identificador)) {
			return (
				<MenuItemMovil
					identificador={identificador}
					titulo={nombre}
					icono={icono}
					menuActivo={menuActivo}
					onClick={evento}
				/>
			);
		}
		return null;
	}
	function onClickSolicitarPersonal() {
		setMenuActivo("portal-colaborador.solicitud-personal");
		history.push("/solicitud-personal");
		setVisibleMenuPerfil(false);
	}

	function onClickEntrevistas() {
		setMenuActivo("portal-colaborador.entrevistas");
		history.push("/entrevistas");
		setVisibleMenuPerfil(false);
	}

	const onClickBtnSeleccionarEmpresa = () => {
		history.push("/movil/configuracion/seleccionar-empresa");
		setVisibleMenuPerfil(false);
	};

	const onClickSolicitudDocumentos = () => {
		setMenuActivo("portal-colaborador.solicitud-documentos");
		history.push("/solicitud-documentos");
		setVisibleMenuPerfil(false);
	};

	const onClickConsulta = () => {
		setMenuActivo("bandejaConsulta");
		history.push("/bandejaConsulta");
		setVisibleMenuPerfil(false);
	};

	return (
		<React.Fragment>
			<div className="container-movil-menu">
				{mostrarMenuItemMovil(
					"bandejaEntrada",
					"Documentos",
					<FileTextOutlined style={{ fontSize: "1.5rem" }} />,
					menuActivo,
					onClickMenuItem
				)}
				{mostrarMenuItemMovil(
					"portal-colaborador.mis-documentos",
					"Documentos",
					<FileTextOutlined style={{ fontSize: "1.5rem" }} />,
					menuActivo,
					onClickMenuItem
				)}
				{mostrarMenuItemMovil(
					"portal-colaborador.v3.mis-pendientes",
					"Pendientes",
					<EditOutlined style={{ fontSize: "1.5rem" }} />,
					menuActivo,
					onClickMenuItem
				)}
				{mostrarMenuItemMovil(
					"portal-colaborador.v3.mis-documentos",
					"Documentos",
					<FolderOpenOutlined style={{ fontSize: "1.5rem" }} />,
					menuActivo,
					onClickMenuItem
				)}
				{/* {mostrarMenuItemMovil(
					"bandejaConsulta",
					"Consultas",
					<ContainerOutlined style={{ fontSize: "1.5rem" }} />,
					menuActivo,
					onClickMenuItem
				)} */}
				{!isAceros && mostrarMenuItemMovil(
					"portal-colaborador.vacaciones",
					"Vacaciones",
					<CalendarOutlined style={{ fontSize: "1.5rem" }} />,
					menuActivo,
					onClickMenuItem
				)}
				{isAceros && mostrarMenuItemMovil(
					"portal-colaborador.vacaciones",
					"Ausentismos",
					<CalendarOutlined style={{ fontSize: "1.5rem" }} />,
					menuActivo,
					onClickMenuItem
				)}

				<MenuItemMovil
					identificador="perfil"
					titulo="Perfil"
					icono={<UserOutlined style={{ fontSize: "1.5rem" }} />}
					menuActivo={menuActivo}
					onClick={onClickMenuItem}
				/>

				<MenuItemMovil
					identificador="menu"
					titulo="Menú"
					icono={<AppstoreOutlined style={{ fontSize: "1.5rem" }} />}
					onClick={onClickMenuItem}
				/>
			</div>

			<Drawer
				title="Opciones"
				placement="right"
				onClose={onCloseMenuPerfil}
				open={visibleMenuPerfil}
				width={"80%"}
			>
				{tieneAcceso("portal-colaborador.solicitud-personal") && (
					<Button
						type="link"
						icon={<UsergroupAddOutlined />}
						style={{
							padding: "0",
							display: "block",
							color: "#7d7d7d",
							fontSize: "16px",
						}}
						onClick={onClickSolicitarPersonal}
					>
						Solicitud Personal
					</Button>
				)}

				{tieneAcceso("portal-colaborador.entrevistas") && (
					<Button
						type="link"
						icon={<ProfileOutlined />}
						style={{
							padding: "0",
							display: "block",
							color: "#7d7d7d",
							fontSize: "16px",
						}}
						onClick={onClickEntrevistas}
					>
						Entrevistas
					</Button>
				)}

				{tieneAcceso("portal-colaborador.tareas-pendientes") && !isAceros && (
					<Button
						type="link"
						icon={<BarsOutlined />}
						style={{
							padding: "0",
							display: "block",
							color: "#7d7d7d",
							fontSize: "16px",
						}}
						onClick={onClickEntrevistas}
					>
						Tareas Pendientes
					</Button>
				)}

				{!isAceros && (
					<Button
						type="link"
						icon={<ProfileOutlined />}
						style={{
							padding: "0",
							display: "block",
							color: "#7d7d7d",
							fontSize: "16px",
						}}
						onClick={onClickBtnSeleccionarEmpresa}
					>
						Seleccionar Empresa
					</Button>
				)}


				{tieneAcceso("portal-colaborador.solicitud-documentos") && (
					<Button
						type="link"
						icon={<ProfileOutlined />}
						style={{
							padding: "0",
							display: "block",
							color: "#7d7d7d",
							fontSize: "16px",
						}}
						onClick={onClickSolicitudDocumentos}
					>
						Solicitud Documentos
					</Button>
				)}

				<Divider />

				{tieneAcceso("bandejaConsulta") && (
					<Button
						type="link"
						icon={<MailOutlined />}
						style={{
							padding: "0",
							display: "block",
							color: "#7d7d7d",
							fontSize: "16px",
						}}
						onClick={onClickConsulta}
					>
						Consultas
					</Button>
				)}

				<Button
					type="link"
					icon={<ImportOutlined />}
					style={{
						padding: "0",
						display: "block",
						color: "#7d7d7d",
						fontSize: "16px",
					}}
					onClick={onClickSalir}
				>
					Salir
				</Button>
			</Drawer>
		</React.Fragment>
	);
}
