import React, { useContext, useEffect } from "react";
import { Button, Card, Modal, Row, Tooltip} from "antd";
//import { cssVisorPdf, cssBodyVisorPdf } from "./estilos"
import { MisDocumentosContext } from "context/v3/MisDocumentosContext";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useState } from "react";
import { SeleccionarTipoCarga } from "./SeleccionarTipoCarga";
import { CargarImagen } from "./CargarImagen";
import { CargarPDF } from "./CargarPDF";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { saveAs } from "file-saver";

export function ModalSubirArchivo() {

    const { visibleModalUpload, cerrarModalUpload, documentoSeleccionado, documentoSeleccionadoId } = useContext(MisDocumentosContext);
    
    const [seleccionado, setSeleccionado] = useState(false)
    const [isImage, setIsImage] = useState(undefined)
    const [urlPlantilla, setUrlPlantilla] = useState("")
    const [habilitarCarga, setHabilitarCarga] = useState(true);

    function onCerrarModal() {
        setSeleccionado(false)
        setIsImage(undefined)
        setUrlPlantilla("")
        cerrarModalUpload();
    }

    useEffect(() => {
        const cargarUrl = async() => {
            const respuesta = await PortalColaboradorService.obtenerFormatoCarga(documentoSeleccionado?.tipoDocumentoId)
            setUrlPlantilla(respuesta.urlDocumento)
        }

        if (documentoSeleccionado?.mostrarlink) {
			setHabilitarCarga(false);
		} else {
			setHabilitarCarga(true);
        }
        
        if(documentoSeleccionadoId!= null)
            cargarUrl();
    }, [documentoSeleccionadoId, documentoSeleccionado])

    const onClickDescargarPlantilla = () => {
        saveAs(urlPlantilla, "Formato Carga - "+ documentoSeleccionado?.nombreDocumento +".pdf") ;
    }
    
    const onClickBtnLink = () => {
		setHabilitarCarga(true);
	};
    return (
        <React.Fragment>
            <Modal
                
                title={<div>{seleccionado && <ArrowLeftOutlined onClick={()=>setSeleccionado(false)} />} Cargar Documento</div>}
                visible={visibleModalUpload}
                onCancel={onCerrarModal}
                centered
                footer={!seleccionado ? [
                <Row key={"row-modal"} justify={"space-between"}>
                    {urlPlantilla!=null && urlPlantilla!= "" ? <Button key="descargar-plantilla" type="primary" onClick={()=>onClickDescargarPlantilla()}>
                        Descargar Plantilla
                        </Button> : <span></span>} 
                    {!habilitarCarga && documentoSeleccionado?.mostrarlink && (
                        <div>
                            <Tooltip defaultOpen="true" title="Para poder cargar sus documentos debe de ver el siguiente tutorial" color="gold" key="gold">
                                <Button
                                    onClick={onClickBtnLink}
                                    type="link"
                                    href={documentoSeleccionado?.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Ver Tutorial
                                </Button> 
                            </Tooltip>
                        </div>
                    )}   
                     <Button key="back" onClick={onCerrarModal}>
                        Cancelar
                    </Button></Row>]:null}
                destroyOnClose={true}
            >
                {habilitarCarga && !seleccionado && <SeleccionarTipoCarga setSeleccionado={setSeleccionado} setIsImage={setIsImage} />}
                {seleccionado && isImage!== undefined && isImage && <CargarImagen onCerrarModal={onCerrarModal}  />}
                {seleccionado && isImage!== undefined && !isImage && <CargarPDF onCerrarModal={onCerrarModal} />}
            </Modal>

        </React.Fragment >
    )
}