import React from "react";
import { Document } from "react-pdf";
import { errorMostradoAtom, loadingAtom, totalPaginasAtom, urlPdfAtom, motivoRechazoAtom } from "../../VisorFormularioStorage";
import { useAtom, useAtomValue } from "jotai";
import { PaginaPdf } from "./componentes";
import { useMediaQuery } from "react-responsive";
import { WarningOutlined } from "@ant-design/icons";

const Visor = ({setLoadingPdf}) => {
	const [totalPaginas, setTotalPaginas] = useAtom(totalPaginasAtom);
	const errorMostrado = useAtomValue(errorMostradoAtom);
	const urlPdf = useAtomValue(urlPdfAtom);
	const [loading, setLoading] = useAtom(loadingAtom);
	const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
	const motivoRechazo = useAtomValue(motivoRechazoAtom);

	const onLoadSuccess = async (successEvent) => {
		setTotalPaginas(successEvent.numPages);
		setLoading(false);
		if (setLoadingPdf !== undefined) {
			setLoadingPdf(false);
		}
	};

	const getHeight = () => {
		if (errorMostrado && motivoRechazo) { 
			return "calc(100% - 112px)";
		} else if (errorMostrado && motivoRechazo=== undefined || errorMostrado === undefined && motivoRechazo) {
			return "calc(100% - 72px)";
		}
		return "calc(100% - 32px)";
	 }

	return (
		<div
			className="visor-formulario-generico-body"
			style={{
				// height: errorMostrado ? "calc(100% - 72px)" : "calc(100% - 32px)",
				height: getHeight(),
				width: esMovil ? "100%" : "60vw",
			}}
		>
			<Document file={urlPdf} onLoadSuccess={onLoadSuccess} loading={null}>
				{Array.from(new Array(totalPaginas), (_, index) => (
					<PaginaPdf key={`page_${index + 1}`} numeroPagina={index + 1} />
				))}
			</Document>
		</div>
	);
};
	
export default Visor;
