import React, { useContext, useEffect } from "react";
import { Button, Card, Modal, Row } from "antd";
//import { cssVisorPdf, cssBodyVisorPdf } from "./estilos"
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useState } from "react";
import { SeleccionarTipoCarga } from "./SeleccionarTipoCarga";
import { CargarImagen } from "./CargarImagen";
import { CargarPDF } from "./CargarPDF";
import { Header } from "./Header";
import { documentoSeleccionadoAtom } from "../MisDocumentosMovilStorage";
import { useAtom } from "jotai";

export function CargarDocumentosPage() {

    const [ documentoSeleccionado,_ ] = useAtom(documentoSeleccionadoAtom)

    const [seleccionado, setSeleccionado] = useState(false)
    const [isImage, setIsImage] = useState(undefined)
    useEffect(() => {
        
      console.log(documentoSeleccionado)
    }, [documentoSeleccionado])
    
    const onRetornar = () => {
        setSeleccionado(false);
        setIsImage(undefined);
    }

    return (
        <React.Fragment>
                <Header seleccionado={seleccionado} onRetornar={onRetornar} />

                {!seleccionado && <SeleccionarTipoCarga setSeleccionado={setSeleccionado} setIsImage={setIsImage} />}
                {seleccionado && isImage !== undefined && isImage && <CargarImagen />}
                {seleccionado && isImage !== undefined && !isImage && <CargarPDF />}
        </React.Fragment >
    )
}