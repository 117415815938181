import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Provider as JotaiProvider } from "jotai";
import { MisDocumentosMovil } from "./componentes/MisDocumentosMovil";
import { MisDocumentosContextProvider } from "../../../context/v3/MisDocumentosContextProvider";
import { MisDocumentosWeb } from "./componentes/MisDocumentosWeb"


const MisPendientesPage = () => {
    const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });
    const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
    return (
        <MisDocumentosContextProvider>
            {esDesktop && <MisDocumentosWeb />}
            {esMovil && <MisDocumentosMovil />}
        </MisDocumentosContextProvider>
    );
};

export default MisPendientesPage;