import { HttpService } from "./HttpService";
import { URL } from "enum";
const URL_API = URL.BASE;

const TareasPendientesService = {
	async listar() {
		const respuesta = await HttpService(URL_API).get("/portal-colaborador/tareas-pendientes");
		return respuesta.data;
	},
	async buscarPorId(tareaPendienteId) {
		const respuesta = await HttpService(URL_API).get(`/portal-colaborador/tareas-pendientes/${tareaPendienteId}`);
		return respuesta.data;
	},
	async firmarDocumento(datos) {
		const respuesta = await HttpService(URL_API).put(
			`/portal-colaborador/tareas-pendientes/firmar-documento`,
			datos
		);
		return respuesta.data;
	},
};
export default TareasPendientesService;
