import React, { useEffect } from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

const subdominio = window.location.host.split(".")[0];

const parametros = window.location.search;
const buscadorParametros = new URLSearchParams(parametros);

const LoginPage = () => {
	let history = useHistory();

	useEffect(() => {
		const cargarDatosIniciales = () => {
			const sesionUrl = buscadorParametros.get("sesionUrl");
			const sesionTab = buscadorParametros.get("sesionTab");
			if (sesionUrl) {
				const urlParametros = sesionTab ? "&sesionTab=" + sesionTab : "";
				if (subdominio === "app") {
					history.push("/g/login?sesionUrl=" + sesionUrl + urlParametros);
				} else {
					history.push("/e/login?sesionUrl=" + sesionUrl + urlParametros);
				}
			} else {
				if (subdominio === "app") {
					history.push("/g/login");
				} else if (subdominio.includes("maccaferri")) {
					history.push("/ad/login");
				} else {
					history.push("/e/login");
				}
			}
		};
		cargarDatosIniciales();
	}, []);

	return (
		<div style={styleContainerLogin}>
			<span className="milegajo-primary-color">
				<ReloadOutlined spin={true} style={{ fontSize: "32px" }} />
			</span>
		</div>
	);
};

const styleContainerLogin = {
	width: "100%",
	height: "100%",
	background: "white",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
};

export default LoginPage;
