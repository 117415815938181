import React, { useState, useEffect, useContext } from "react";
import {
	Form,
	Input,
	Row,
	Col,
	Button,
	Space,
	Modal,
	notification,
	message,
} from "antd";
import { ContainerCrear } from "../../../components/ContainerCrear";
import FormItem from "antd/lib/form/FormItem";
import { CheckOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import GestionarClaveService from "../../../services/GestionarClaveService";
import ActualizarClaveService from "../../../services/ActualizarClaveService";
import ModalGenerarCodigo from "./modalGenerarCodigo";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { VersionApiColaborador } from "enum/VersionApiColaborador";

const host = window.location.host;
const subdominio = host.split(".")[0];

export function CambiarClavePage() {
	const [listaCorreos, setListaCorreos] = useState([]);
	const [correoSeleccionado, setCorreoSeleccionado] = useState("");
	const [defaultValorCorreo, setDefaultValorCorreo] = useState("");

	const [visibleModal, setVisibelModal] = useState(false);
	const [formularioCambiarClave] = Form.useForm();
	const { getVersionApiColaborador, getUsuarioAutenticado } = useContext(
		SecurityContext
	);
	const [loading, setLoading] = useState(false);
	let history = useHistory();

	// useEffect(() => {
	// 	async function cargarDatos() {

	// 	}
	// 	cargarDatos();
	// }, []);

	function OnModal(correo) {
		Modal.info({
			title: "Generar código de verificación Clave",
			content: (
				<div>
					<p>
						Se le enviará el código de seguridad al correo:{" "}
						<span>{correo}</span>
					</p>
				</div>
			),
			onOk() {
				handleOnOKGenerarCodigo(correo);
			},
		});
	}

	async function handleOnOKGenerarCodigo(correo) {
		try {
			await GestionarClaveService.crearCodigoSeguridad(
				correo,
				getUsuarioAutenticado().numeroDocumentoIdentidad,
				subdominio
			);
			notification.success({
				message: "Operación exitosa",
				description: "Se envío el codigo de seguridad correctamente.",
			});
		} catch (error) {
			console.error(error.response);
			console.error(error.response.data.mensaje);
			notification.error({
				message: "No se puede enviar el codigo de seguridad",
				description: "Error-> " + error.response.data.mensaje,
			});
		}
	}

	async function handleOnFinish(formulario) {
		try {
			setLoading(true);
			await ActualizarClaveService.cambiarClave(
				subdominio,
				getUsuarioAutenticado().numeroDocumentoIdentidad,
				formulario.clave,
				formulario.claveActual
			);
			notification.success({
				message: "Operación exitosa",
				description: "Se actualizo su clave.",
			});
			setLoading(false);

			if (getVersionApiColaborador() === VersionApiColaborador.v1) {
				history.push("/bandejaEntrada");
			} else if (
				getVersionApiColaborador() === VersionApiColaborador.v2
			) {
				history.push("/mis-documentos");
			} else {
				history.push("/bandejaEntrada");
			}
		} catch (error) {
			setLoading(false);
			console.error(error.response);
			console.error(error.response.data.mensaje);
			notification.error({
				message: "No se puede guardar la clave",
				description: "Error-> " + error.response.data.mensaje,
			});
		}
	}
	function handleOnClickCancelar() {
		history.push("/legajo/listar");
	}
	function onCerrarModal() {
		setVisibelModal(false);
	}

	function handleOk() {
		handleOnOKGenerarCodigo(correoSeleccionado);
	}

	function handleOnChangeCorreo(value) {
		setCorreoSeleccionado(value);
	}

	async function handleOnClickGenerarCodigo() {
		if (
			getUsuarioAutenticado().numeroDocumentoIdentidad === undefined ||
			getUsuarioAutenticado().numeroDocumentoIdentidad === ""
		) {
			message.error("Número Documento Identidad no existe");
		} else {
			const numeroDocumentoIdentidad = getUsuarioAutenticado()
				.numeroDocumentoIdentidad;
			const correos = await GestionarClaveService.buscarCorreo(
				subdominio,
				numeroDocumentoIdentidad
			);

			if (correos.length === 1) {
				OnModal(correos[0]);
			} else if (correos.length > 1) {
				setDefaultValorCorreo(correos[0]);
				setCorreoSeleccionado(correos[0]);
				setListaCorreos(correos);
				setVisibelModal(true);
			}
		}
	}
	return (
		<ContainerCrear titulo="Cambiar Clave" colSpan={12}>
			<Form
				form={formularioCambiarClave}
				labelCol={{
					span: 12,
				}}
				wrapperCol={{
					span: 12,
				}}
				layout="vertical"
				size="default"
				onFinish={handleOnFinish}
			>
				<Row align="center">
					<Col span={20}>
						<FormItem
							label="Clave Actual"
							name="claveActual"
							rules={[
								{
									required: true,
									message: "Ingrese su clave actual",
								},
							]}
						>
							<Input.Password />
						</FormItem>
					</Col>
				</Row>
				<Row align="center">
					<Col span={20}>
						<FormItem
							label="Clave"
							name="clave"
							rules={[
								{
									required: true,
									message: "Ingrese su clave",
								},
							]}
						>
							<Input.Password />
						</FormItem>
					</Col>
				</Row>
				<Row align="center">
					<Col span={20}>
						<FormItem
							label="Repetir Clave"
							name="claveR"
							dependencies={["clave"]}
							hasFeedback
							rules={[
								{
									required: true,
									message: "Ingrese nuevamente su clave",
								},
								({ getFieldValue }) => ({
									validator(rule, value) {
										if (
											!value ||
											getFieldValue("clave") === value
										) {
											return Promise.resolve();
										}

										return Promise.reject(
											"Las claves deben ser iguales!"
										);
									},
								}),
							]}
						>
							<Input.Password />
						</FormItem>
					</Col>
				</Row>

				{/* <Row>
          <FormItem label="Código">
            <Row gutter={8}>
              <Col span={12}>
                <FormItem
                  name="codigo"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese código",
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingrese código"
                    autoComplete="off"
                  ></Input>
                </FormItem>
              </Col>
              <Col span={8}>
                <Button onClick={handleOnClickGenerarCodigo}>
                  Solicitar código
                </Button>
              </Col>
            </Row>
          </FormItem>
        </Row> */}

				<Row justify="center">
					<Space>
						<Col span={20}>
							<Button
								type="primary"
								icon={<CheckOutlined />}
								htmlType="submit"
								loading={loading}
							>
								Guardar
							</Button>
						</Col>
						<Col>
							<Button
								type="default"
								onClick={handleOnClickCancelar}
							>
								Cancelar
							</Button>
						</Col>
					</Space>
				</Row>
			</Form>
			<ModalGenerarCodigo
				visibleModal={visibleModal}
				oncerrarModal={onCerrarModal}
				handleOk={handleOk}
				defaultValorCorreo={defaultValorCorreo}
				handleOnChangeCorreo={handleOnChangeCorreo}
				listaCorreos={listaCorreos}
			/>
		</ContainerCrear>
	);
}
