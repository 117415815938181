import React from "react";
import { FileImageFilled, FilePdfFilled } from '@ant-design/icons';
import { Card, Modal, Row } from "antd";

export function SeleccionarTipoCarga({setSeleccionado, setIsImage}) {
    const { Meta } = Card
    
    const gridStyle = {
    width: '50%',
    textAlign: 'center',
    };
    return (
        <Row>
                <Card
                    hoverable
                    style={gridStyle}
                    cover={ <FileImageFilled style={{ fontSize: '50px', marginTop:"20px" }} />}
                onClick={() => { setSeleccionado(true);  setIsImage(true)}}
                >
                    <Meta title="Seleccionar Imagen" />
                </Card>
                <Card
                    hoverable
                    style={gridStyle}
                    cover={ <FilePdfFilled style={{ fontSize: '50px', marginTop:"20px" }} />}
                onClick={() => { setSeleccionado(true); setIsImage(true);  setIsImage(false) }} 
                >
                    <Meta title="Seleccionar PDF"/>
                </Card>
            </Row>
    )

}