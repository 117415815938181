import React from "react";
import { useMediaQuery } from "react-responsive";
import { Provider as JotaiProvider } from "jotai";
import { RenovacionContratoPageWeb } from "./componentes";

const RenovacionContratosPage = () => {
	const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
	const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });

	return (
		<JotaiProvider>
			{/* {esMovil && <TareasPendientesPageMovil />} */}
			{esDesktop && <RenovacionContratoPageWeb />}
		</JotaiProvider>
	);
};

export default RenovacionContratosPage;
