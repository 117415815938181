import { useEffect } from "react";
import { useAtom, useSetAtom } from "jotai";
import { OnboardingService } from "services";
import {
	pasoActualAtom, statusOnboardingAtom,
	estadoFirmaOnboardingAtom, estadoHuellaOnboardingAtom,
informacionPersonalCompletadoAtom} from "pages/onboarding/OnboardingStore";

const useStatusOnboarding = () => {
	const [statusOnboarding, setStatusOnboarding] = useAtom(statusOnboardingAtom);
	const setPasoActual = useSetAtom(pasoActualAtom);
	const [estadoFirma, setEstadoFirma] = useAtom(estadoFirmaOnboardingAtom);
	const [estadoHuella, setEstadoHuella] = useAtom(estadoHuellaOnboardingAtom);
	const [informacionPersonalCompletado, setInformacionPersonalCompletado] = useAtom(informacionPersonalCompletadoAtom);

	useEffect(() => {
		const cargarDatos = async () => {
			const statusOnboarding = await OnboardingService.obtenerStatusOnboarding();
			setStatusOnboarding(statusOnboarding);
			setPasoActual(statusOnboarding.etapa);
			setEstadoFirma(statusOnboarding.estadoImagenFirma);
			setEstadoHuella(statusOnboarding.estadoImagenHuella);
			setInformacionPersonalCompletado(statusOnboarding.informacionPersonalCompletado);
		};
		cargarDatos();
	}, []);

	return { statusOnboarding };
};

export default useStatusOnboarding;
