import Axios from "axios";

const LoginService = {
	async autenticar(usuario, clave) {
		const respuesta = await Axios.post(process.env.REACT_APP_ENDPOINT_LOGIN, {
			login: usuario,
			clave: clave,
		});
		return respuesta.data;
	},

	async autenticarConTokenFirebase(usuario, clave, tokenFirebase) {
		const respuesta = await Axios.post(process.env.REACT_APP_ENDPOINT_LOGIN, {
			login: usuario,
			clave: clave,
			tokenFirebase: tokenFirebase,
		});
		return respuesta.data;
	},

	async redireccionar(subdominio, hashUrl) {
		const respuesta = await Axios.get(process.env.REACT_APP_ENDPOINT + "r", {
			params: {
				subdominio: subdominio,
				hashUrl: hashUrl,
			},
		});
		return respuesta.data;
	},

	async cargarDatosIniciales(subdominio) {
		const respuesta = await Axios.post(process.env.REACT_APP_ENDPOINT_GESTIONAR_CLAVE + "/cargarDatosIniciales", {
			subdominio: subdominio,
		});
		return respuesta.data;
	},

	getJwtToken() {
		return localStorage.getItem("jwt-token-colaborador");
	},

	getUsuarioAutenticado() {
		return JSON.parse(localStorage.getItem("jwt-sesion-colaborador"));
	},

	isJefeProyecto() {
		return (
			JSON.parse(localStorage.getItem("jwt-sesion-colaborador")).empresaSeleccionada.colaborador.jefeProyecto ===
			true
		);
	},

	isAdministradorObra() {
		return (
			JSON.parse(localStorage.getItem("jwt-sesion-colaborador")).empresaSeleccionada.colaborador
				.administradorObra === true
		);
	},

	cerrarSesion() {
		localStorage.removeItem("jwt-token-colaborador");
		localStorage.removeItem("jwt-sesion-colaborador");
		localStorage.removeItem("plataforma");
		localStorage.removeItem("FCMToken");
	},
};

export default LoginService;
