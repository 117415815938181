import { BaseService } from "./BaseService";
const TipoDocumentoService = {
	async listar(empresaId) {
		const respuesta = await BaseService.get("/tipoDocumento/listarPorEmpresaYResponsableCargaDocumento", {
			params: {
				empresaId: empresaId,
			},
		});
		return respuesta.data;
	},
	async listarDocumentosPersonales(empresaId, categoriaDocumentoId) {
		const respuesta = await BaseService.get("/tipoDocumento", {
			params: {
				empresaId: empresaId,
				categoriaDocumentoId: categoriaDocumentoId,
			},
		});
		return respuesta.data;
	},
};
export default TipoDocumentoService;
