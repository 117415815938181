import { atom } from "jotai";

export const formularioSeleccionadoAtom = atom(undefined);
export const visibleVisorFormularioAtom = atom(false);
export const erroresFormularioAtom = atom({});
export const signatureModalVisibleAtom = atom(false);
export const signaturesMetadataAtom = atom([]);
export const componentSelectedAtom = atom(undefined);
export const zoomVisorFormularioAtom = atom(1.25);

export const urlFirmaHolografaAtom = atom(undefined);
export const urlHuellaDactilarAtom = atom(undefined);
export const urlFotoAtom = atom(undefined);

export const visibilidadComponentesAtom = atom({});
export const loadingPdfAtom = atom(true);