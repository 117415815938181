import React from "react";
import logotipo from "../../../../../../assets/imagenes/logotipo.png";
import "./Header.css";

const Header = () => {
	return (
		<div className="container-movil-pdf-header">
			<img
				src={logotipo}
				alt=""
				style={{ height: "36px", marginRight: "10px" }}
			/>
				Mis Documentos
		</div>
	);
}

export default Header;