import React from "react";
import { Alert, Input, Button, Modal } from "antd";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
	datosFormularioAtom,
	visibleModalFirmarAtom,
	documentoSeleccionadoAtom,
	documentosAtom,
} from "pages/onboarding/OnboardingStore";
import { useState } from "react";
import { Plataforma } from "enum/Plataforma";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { EstadoVistaColaborador } from "enum/EstadoVistaColaborador";
import produce from "immer";
import { useEffect } from "react";
import { EstadoAprobacion } from "enum/EstadoAprobacion";

const ModalFirmar = ({ plataforma = Plataforma.WEB }) => {
	const [claveIngresada, setClaveIngresada] = useState("");
	const [visible, setVisible] = useAtom(visibleModalFirmarAtom);
	const [loading, setLoading] = useState(false);
	const [datosFormulario, setDatosFormulario] = useAtom(datosFormularioAtom);
	const [mensajeError, setMensajeError] = useState(undefined);
	const [documentoSeleccionado, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
	//const [documentos, setDocumentos] = useAtom(documentosAtom);
	const setDocumentos = useSetAtom(documentosAtom);

	const onClickBtnFirmar = async () => {
		try {
			setLoading(true);

			await PortalColaboradorService.completarFormulario({
				detalleEnvioId: documentoSeleccionado.id,
				datos: datosFormulario,
				clave: claveIngresada,
				plataforma,
			});

			setDocumentoSeleccionado({
				...documentoSeleccionado,
				estadoVistaColaborador: EstadoVistaColaborador.FIRMADO,
				estadoAprobacion: EstadoAprobacion.PENDIENTE
			});

			setDocumentos(
				produce((draft) => {
					const documento = draft.find((documento) => documento.id === documentoSeleccionado.id);
					documento.estadoVistaColaborador = EstadoVistaColaborador.FIRMADO;
					documento.estadoAprobacion = EstadoAprobacion.PENDIENTE;
				})
			);

			setClaveIngresada("");
			setMensajeError(undefined);
			setVisible(false);
		} catch (error) {
			console.error(error?.response?.data?.mensaje);
			setMensajeError(error?.response?.data?.mensaje);
		} finally {
			setLoading(false);
		}
	};

	const onClickBtnCancelar = () => {
		setVisible(false);
	};

	return (
		<Modal
			title="Firmar Documento"
			open={visible}
			closable={false}
			width={400}
			destroyOnClose={true}
			footer={[
				<Button key="cancelar" disabled={loading} onClick={onClickBtnCancelar} style={{ padding: "0px 30px" }}>
					Cancelar
				</Button>,
				<Button
					key="firmar"
					type="primary"
					loading={loading}
					onClick={onClickBtnFirmar}
					style={{ padding: "0px 30px" }}
				>
					Firmar
				</Button>,
			]}
			centered
		>
			{mensajeError && (
				<Alert
					message={mensajeError}
					onClose={() => setMensajeError(undefined)}
					style={{ marginBottom: "4px" }}
					type="error"
					showIcon
					closable
				/>
			)}

			<span style={{ display: "block", marginBottom: "4px" }}>Ingresa tu clave</span>
			<Input.Password
				value={claveIngresada}
				onChange={(e) => {
					setClaveIngresada(e.target.value);
					setMensajeError(undefined);
				}}
				placeholder={"Clave"}
			/>
		</Modal>
	);
};

export default ModalFirmar;
