import React, { useContext, useEffect } from "react";
import { Button, Card, Modal, Row, Alert } from "antd";
//import { cssVisorPdf, cssBodyVisorPdf } from "./estilos"
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useState } from "react";
import { SeleccionarTipoCarga } from "./SeleccionarTipoCarga";
import { CargarImagen } from "./CargarImagen";
import { CargarPDF } from "./CargarPDF";
import { Header } from "./Header";
import { documentoSeleccionadoAtom } from "../MisDocumentosMovilStorage";
import { useAtom } from "jotai";

export function CargarDocumentosPageV3() {

    const [ documentoSeleccionado,_ ] = useAtom(documentoSeleccionadoAtom)

    const [seleccionado, setSeleccionado] = useState(false)
    const [isImage, setIsImage] = useState(undefined)
    const [habilitarCarga, setHabilitarCarga] = useState(true);

    useEffect(() => {
        console.log(documentoSeleccionado)
        if (documentoSeleccionado?.mostrarlink) {
			setHabilitarCarga(false);
		}else {
			setHabilitarCarga(true);
		}
    }, [documentoSeleccionado])
    
    const onRetornar = () => {
        setSeleccionado(false);
        setIsImage(undefined);
    }

    const onClickBtnLink = () => {
		setHabilitarCarga(true);
    };
    
    return (
        <React.Fragment>
                <Header seleccionado={seleccionado} onRetornar={onRetornar} />
                {!habilitarCarga && documentoSeleccionado?.mostrarlink && (
                    <Card extra={<Button
                                    onClick={onClickBtnLink}
                                    type="link"
                                    href={documentoSeleccionado?.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                   Ver Tutorial
                                </Button>}>
                        <Alert message='Para poder cargar sus documentos debe de ver el Tutorial' type="warning" showIcon />
                    </Card>
                    )
                }
                {habilitarCarga && !seleccionado && <SeleccionarTipoCarga setSeleccionado={setSeleccionado} setIsImage={setIsImage} />}
                {seleccionado && isImage !== undefined && isImage && <CargarImagen />}
                {seleccionado && isImage !== undefined && !isImage && <CargarPDF />}
        </React.Fragment >
    )
}