import { BaseService } from "./BaseService";

const SeccionService = {
   async listarPorNombreSede(empresaId, nombreSede) {
    const respuesta = await BaseService.get("/seccion/listarPorNombreSede", {
      params: {
        empresaId:empresaId,
        nombreSede: nombreSede
      },
    });
    return respuesta.data;
  }, 
}
export default SeccionService;