import React, { useState, useContext } from "react";
import { Dropdown, Menu, Table } from "antd";
import {
	UnorderedListOutlined,
	CaretDownOutlined,
	CheckCircleOutlined,
	CloseCircleOutlined,
	SearchOutlined,
} from "@ant-design/icons";
import { useListarSolicitudesAusenciaPorJefeInmediato } from "../../hooks";
import moment from "moment";
import { useAtom } from "jotai";
import {
	solicitudAusenciaSeleccionadaAtom,
	visibleModalAprobarSolicitudAusenciaAtom,
	visibleModalRechazarSolicitudAusenciaAtom,
	visibleModalVerDocumentoAdjuntoAtom,
	urlDocumentoAdjuntoAtom,
} from "../../SolicitudesPendientesAprobarStore";

import { SecurityContext } from "context/SecurityContextProvider";
import { useModalVisorPdf } from "store/global";
import { SolicitudAusenciaService } from "services";
import { Plataforma } from "enum/Plataforma";

const ListaSolicitudesPendientesAprobacion = () => {
	const { solicitudesAusencia } = useListarSolicitudesAusenciaPorJefeInmediato("PENDIENTE");
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isMaccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const isMaccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;
	const isAceros = getUsuarioAutenticado().empresaSeleccionada.razonSocial.toUpperCase().includes("ACEROS CHILCA SAC")
		? true
		: false;
	console.log("solicitudesAusencia", solicitudesAusencia);
	return (
		<Table
			rowKey={(value) => value.id}
			columns={columnas}
			dataSource={solicitudesAusencia}
			expandable={{
				expandedRowRender: (fila) => <Comentario registro={fila} />,
				rowExpandable: (fila) =>
					((isMaccaferriConstruction || isMaccaferriDePeru) &&
						fila.comentario !== null &&
						fila.comentario !== undefined &&
						fila.comentario !== "") ||
					(isAceros && fila.informacionAdicional !== null),
			}}
		/>
	);
};

const columnas = [
	{
		title: "Colaborador",
		dataIndex: ["colaborador", "nombreCompleto"],
		width: "20%",
	},
	{
		title: "Tipo Ausencia",
		dataIndex: ["tipo", "descripcion"],
		width: "15%",
		align: "center",
	},
	{
		title: "Fecha Inicio",
		dataIndex: "fechaInicioAusencia",
		width: "15%",
		align: "center",
		render: (text, fila) => moment(fila.fechaInicioAusencia).format("DD/MM/YYYY hh:mm a"),
	},
	{
		title: "Fecha Fin",
		dataIndex: "fechaFin",
		width: "15%",
		align: "center",
		render: (text, fila) => moment(fila.fechaFinAusencia).format("DD/MM/YYYY hh:mm a"),
	},
	{
		title: "Dias",
		dataIndex: "diasSolicitados",
		width: "5%",
		align: "center",
	},
	{
		title: "Fecha de Solicitud",
		dataIndex: "fechaHoraSolicitud",
		width: "15%",
		align: "center",
		render: (text, fila) => moment(fila.fechaHoraSolicitud).format("DD/MM/YYYY hh:mm a"),
	},
	{
		title: "Acciones",
		align: "center",
		width: "15%",
		render: (text, fila) => <MenuAcciones solicitud={fila} />,
	},
];

const MenuAcciones = ({ solicitud }) => {
	const [visibleMenu, setVisibleMenu] = useState(false);
	const [, setSolicituduAusenciaSeleccionada] = useAtom(solicitudAusenciaSeleccionadaAtom);
	const [, setVisibleModalAprobarSolicitudAusencia] = useAtom(visibleModalAprobarSolicitudAusenciaAtom);
	const [, setVisibleModalRechazarSolicitudAusencia] = useAtom(visibleModalRechazarSolicitudAusenciaAtom);
	const [, setVisibleModalVerDocumentoAdjunto] = useAtom(visibleModalVerDocumentoAdjuntoAtom);
	const [, setUrlDocumento] = useAtom(urlDocumentoAdjuntoAtom);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);

	const onClickMenu = async ({ key }) => {
		if (key === "aprobar") {
			onClickOpcionAprobar();
		} else if (key === "rechazar") {
			onClickOpcionRechazar();
		} else if (key === "verSolicitud") {
			onclickVerSolicitud();
		}
	};

	const onClickOpcionAprobar = () => {
		setVisibleModalAprobarSolicitudAusencia(true);
		setSolicituduAusenciaSeleccionada(solicitud);
		setVisibleMenu(false);
	};

	const onClickOpcionRechazar = () => {
		setVisibleModalRechazarSolicitudAusencia(true);
		setSolicituduAusenciaSeleccionada(solicitud);
		setVisibleMenu(false);
	};
	const onclickVerSolicitud = () => {
		console.log("ver solicitud");
		setSolicituduAusenciaSeleccionada(solicitud);
		setUrlDocumento("");
		setVisibleModalVerDocumentoAdjunto(true);
		// const respuesta = await SolicitudAusenciaService.obtenerUrl(getUsuarioAutenticado().empresaSeleccionada.id, solicitud.id, Plataforma.WEB);
		// mostrarModalVisorPdf(respuesta.data.urlSolicitud, "Solicitud " + solicitud.tipo.descripcion, true, true);
		// setVisibleMenu(false);
	};

	const menu = () => {
		return (
			<Menu onClick={onClickMenu}>
				<Menu.Item key="aprobar">
					<CheckCircleOutlined /> Aprobar Solicitud
				</Menu.Item>
				<Menu.Item key="rechazar">
					<CloseCircleOutlined /> Rechazar Solicitud
				</Menu.Item>
				{solicitud?.adjuntos && (
					<Menu.Item key="verSolicitud">
						<SearchOutlined /> Ver Solicitud
					</Menu.Item>
				)}
			</Menu>
		);
	};

	return (
		<React.Fragment>
			<Dropdown
				overlay={menu}
				trigger={["click"]}
				placement="bottomCenter"
				onVisibleChange={(flag) => setVisibleMenu(flag)}
				visible={visibleMenu}
			>
				<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
					<UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
					<CaretDownOutlined
						style={{
							paddingLeft: "2px",
							fontSize: "12px",
							fontWeight: "bold",
							color: "#1678c1",
						}}
					/>
				</a>
			</Dropdown>
		</React.Fragment>
	);
};

const Comentario = ({ registro }) => {
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isMaccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const isMaccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;
	const isAceros = getUsuarioAutenticado().empresaSeleccionada.razonSocial.toUpperCase().includes("ACEROS CHILCA SAC")
		? true
		: false;

	return (
		<React.Fragment>
			{(isMaccaferriDePeru || isMaccaferriConstruction) && (
				<p style={{ margin: 5 }}>{"EMPLEADO DESIGNADO: " + registro.comentario}</p>
			)}
			{isAceros && (
				<div style={{ marginLeft: 50 }}>
					<p>
						<strong>Fecha sobre tiempo: </strong>
						{moment(registro.informacionAdicional.fechaSobreTiempo).format("DD/MM/YYYY")}
					</p>
					<p>
						<strong>Día de compensación: </strong>{" "}
						{moment(registro.informacionAdicional.diaCompensacion).format("DD/MM/YYYY")}
					</p>
					<p>
						<strong>Cantidad de horas: </strong> {registro.informacionAdicional.cantidadHoras}
					</p>
					<p>
						<strong>Horas compensadas: </strong> {registro.informacionAdicional.horasCompensadas}
					</p>
					<p>
						<strong>Comentario: </strong> {registro.comentario}
					</p>
				</div>
			)}
		</React.Fragment>
	);
};
export default ListaSolicitudesPendientesAprobacion;
