import React from "react";
import { useMediaQuery } from "react-responsive";
import { Provider as JotaiProvider } from "jotai";
import { PerfilPageWeb } from "./componentes";
const PerfilPage = () => {
	const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
	const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });
	return (
		<JotaiProvider>
			{/* {esMovil} */}
			{esDesktop && <PerfilPageWeb />}
		</JotaiProvider>
	);
};
export default PerfilPage;
